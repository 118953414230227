import React, { useContext, useState, useEffect } from "react";

import { colors } from "@material-ui/core";
import { Button, Form, Checkbox, Divider } from "semantic-ui-react";

import { AppContext } from "../../AppContext";
import { assignSpecialCredits } from '../../apiclients/WalletApiClient';
import NumberInput from "../../lib/NumberInput";
import ModalPortal from '../generic/ModalPortal';
import CustomerDetail from '../common/CustomerDetail';
import GenericLoader from "../generic/GenericLoader";
import { PLAYALL_WALLET, SUB_SOURCES } from "../../utils/constants";
import Payment from "../dashboard/Payment";

const AssignSpecialCreditsModal = (props) => {

    const { setShowWalletAssignModal, assignData, setMessage, setReload, isLoading } = props;
    const context = useContext(AppContext);
    let sports = context.arenaData[
        context.selectedArena.arenaId
    ].sports.map((x, index) => ({
        sportId: x.sportId,
        sportName: x.sportName,
    }));

    const [userName, setUserName] = useState("");
    const [userMobile, setUserMobile] = useState("");
    const [credits, setCredits] = useState("");
    const [amount, setAmount] = useState("");
    const [validity, setValidity] = useState("");
    const [details, setDetails] = useState('');
    const [selectedSports, setSelectedSports] = useState(sports);
    const [freezeData, setFreezeData] = useState(false);
    const [sportsList, setSportsList] = useState(sports);
    const [paymentArray, setPaymentArray] = useState([]);
    const [totalPayment, setTotalPayment] = useState(0);
    const [isPlayAllWallet, setIsPlayAllWallet] = useState(true);
    const [hoursPerDay, setHoursPerDay] = useState();
    const [advanceBooking, setAdvanceBooking] = useState();

    // const [validity, setValidity] = useState();
    useEffect(() => {
        if (assignData !== null) {
            // remove from assingData sports that are not in sportsList and show the balance
            const balanceSports = assignData.sports.filter((x) => sports.some((y) => y.sportId === x.sportId));
            setSportsList(balanceSports);
            setIsPlayAllWallet(false);
            setCredits(assignData.credits);
            setAmount(assignData.amount);
            setValidity(assignData.validity);
            setDetails(assignData.details);
            setSelectedSports(balanceSports);
            setFreezeData(true);
        }
        console.log(isPlayAllWallet);
    }, [])// eslint-disable-line react-hooks/exhaustive-deps
    // const isSubmitAllowed = credits > 0 && amount >= 0 && (isPlayAllWallet ? true: validity > 0)  && selectedSports.length > 0 && userName && userMobile && totalPayment === Number(amount);
    const isSubmitAllowed = credits > 0 && amount >= 0 && (isPlayAllWallet ? true: validity > 0)  && selectedSports.length > 0 && userName && userMobile && totalPayment === Number(amount);

    function handleNumberChange(evt) {
        const numericValue = evt.target.validity.valid ? evt.target.value : userMobile;
        setUserMobile(numericValue);
    }

    const updatePaymentArray = (updatedArray) => {
        const amountPaid = updatedArray.reduce((a, b) => a + Number(b.amount), 0);
        setTotalPayment(amountPaid);
        setPaymentArray(updatedArray);

    };

    const modalContent = (
        <div>
            <Form
                onKeyPress={(e) => {
                    if (e.key === 'Enter') e.preventDefault();
                }}
            >
                <CustomerDetail
                    name={userName}
                    setName={setUserName}
                    number={userMobile}
                    setNumber={handleNumberChange}
                />
                <Form.Group widths='equal'>
                    <Form.Field >
                        <label>Credits</label>
                        <input
                            type="number"
                            placeholder='Amount of Credits'
                            onChange={(evt) => setCredits(evt.target.value)}
                            value={credits}
                            disabled={freezeData}
                        />
                    </Form.Field>
                    <Form.Field >


                        <label>Amount</label>
                        <input
                            type="number"
                            placeholder='Amount Charged'
                            onChange={(evt) => setAmount(evt.target.value)}
                            value={amount}
                            disabled={freezeData}
                        />

                    </Form.Field>
                   {/* {assignData && <Form.Field >
                        <label>Validity</label>
                        <input
                            type="number"
                            placeholder='Valid for # of days'
                            onChange={(evt) => setValidity(evt.target.value)}
                            value={validity}
                            disabled={freezeData}
                        />
                    </Form.Field> } */}
                </Form.Group>
                <Form.Group widths='equal'>
                <Form.Field  >
                        <label>Validity</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='text'
                            value={validity}
                            placeholder='Validity'
                            onChange={(evt) => setValidity(evt.target.value)}

                        />
                    </Form.Field>
                    <Form.Field  >
                        <label>Hours Per Day</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='text'
                            value={hoursPerDay}
                            placeholder='Slots Per Day'
                            onChange={(evt) => setHoursPerDay(evt.target.value)}

                        />
                    </Form.Field>
                   
                    <Form.Field  >
                        <label>Advance Booking</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='text'
                            value={advanceBooking}
                            placeholder='Advance Booking'
                            onChange={(evt) => setAdvanceBooking(evt.target.value)}

                        />
                    </Form.Field>
                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Field >
                        <label>Details</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='text'
                            value={details}
                            placeholder='Other Details'
                            onChange={(evt) => setDetails(evt.target.value)}
                            disabled={freezeData}
                        />
                    </Form.Field>
                </Form.Group>
                <Form.Group widths='equal'>

                    <Form.Field >

                        <label>
                            Sports
                            <Checkbox
                                checked={
                                    sportsList.length === selectedSports.length
                                }
                                style={{ marginRight: "7px", marginLeft: "7px" }}
                                onClick={(e, { checked }) => {
                                    updateAllSports(checked);
                                }}
                            />


                            <span style={{ fontWeight: "lighter" }}> All Sports </span>
                        </label>
                        {sportsList.map((x) => {
                            const isActive = selectedSports.some(
                                (mode) => mode.sportId === x.sportId
                            );
                            return (
                                <Button
                                    key={x.sportId}
                                    onClick={(e) => { updateSelectedSports(x, x.sportId) }}
                                    style={{
                                        marginRight: "10px",
                                        backgroundColor: isActive
                                            ? colors.orange[700]
                                            : colors.grey[200],
                                        color: isActive ? colors.blue[50] : colors.black,
                                    }}
                                >
                                    {x.sportName}
                                </Button>
                            );
                        })}

                    </Form.Field>
                </Form.Group>
                
                <>
                    <Divider horizontal>
                        <span style={{ color: colors.blue["400"] }}>Payment</span>
                    </Divider>
                    <div style={{ marginBottom: "7px", fontWeight: "bold" }}>
                        Total Amount - Rs. {amount}/-
                    </div>
                    <div style={{ marginBottom: "7px", fontWeight: "bold" }}>
                        Payment Breakup
                    </div>
                    <Payment
                        paymentArray={paymentArray}
                        setPaymentArray={updatePaymentArray}
                    />
                    <div style={{ paddingTop: "10px" }}>
                        <span style={{ fontWeight: "bold" }}>Total Payment: Rs. </span>
                        <span>{totalPayment}/-</span>
                    </div>
                </>

            </Form>
        </div>
    );

    const renderAction = () => {
        return (
            <React.Fragment>
                <Button 
                 disabled={!isSubmitAllowed}
                 primary onClick={asignCreditsToUsers}>  Submit</Button>
                <Button onClick={() => setShowWalletAssignModal(false)} className='ui button'>Cancel</Button>
            </React.Fragment>
        );
    };

    const updateAllSports = (checked) => {
        if (checked) {
            setSelectedSports(sportsList);
        } else {
            setSelectedSports([]);
        }
    };

    const updateSelectedSports = (obj, key) => {
        if (selectedSports.some(o => obj.sportId === o.sportId)) {
            setSelectedSports(selectedSports.filter(o => obj.sportId !== o.sportId));
        } else {
            setSelectedSports([...selectedSports, obj]);
        }

    };


    const asignCreditsToUsers = () => {
        setShowWalletAssignModal(false);
        isLoading(true);

        const arenaData = context.arenaData[context.selectedArena.arenaId];
        const postBody = {
            facilityId: context.selectedFacility,

            arenaId: context.selectedArena.arenaId,
            arenas:   [context.selectedArena.arenaId],
            sports:selectedSports,
            userMobile,
            userName,
            advanceBooking: advanceBooking,
            credits : Number(credits),
            details,
            // packageName: assignData ? assignData.packageName : PLAYALL_WALLET.name,
            amount : Number(amount),
            hoursPerDay: hoursPerDay,
            validity: validity,
            createdBy: {
                userId: context.user.userId,
                userName: context.user.userName,
            },
            source: SUB_SOURCES.WEB,
            // isPlayAllWallet,
            paymentInfo: paymentArray,
            arenaData,
            walletId:   null,
        };
// console.log(postBody);
        assignSpecialCredits(
            context.selectedFacility,
            context.selectedArena.arenaId,
            postBody
        ).then((response) => {
            isLoading(false);
            if (response.status === 200 || response.status === 201) {
                setMessage({
                    header: "Credits Assigned",
                    message: "Credits has been assigned successfully.",
                    color: "green",
                });
            } else {
                setMessage({
                    header: "Error!",
                    message: response.data.error.message,
                    color: "red",
                });
            }
            setReload(true);
        }).catch((error) => {

            isLoading(false);
            setMessage({
                header: "Error!",
                message: error.message,
                color: "red",
            });
            // ..
        });
    }

    return (
        <>
            <ModalPortal
                title='Assign Credits'
                content={modalContent}
                action={renderAction()}
                onDismiss={() => setShowWalletAssignModal(false)}
            />
        </>

    );
}


export default AssignSpecialCreditsModal;
