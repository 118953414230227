import React, { useState, useContext, useEffect } from 'react';
import {
    Button, Table
} from "semantic-ui-react";
import { colors } from "@material-ui/core";
import { AppContext } from '../../AppContext';
import ModalPortal from '../generic/ModalPortal';
import {convertFirstoreDate} from "../../utils/helperFunctions";
import moment from 'moment';
import {loadCouponTransactions} from '../../apiclients/ReportsApiClient';
import GenericLoader from "../generic/GenericLoader";
import TransactionDetails from "../reports/TransactionDetails";
import Toast from "../generic/Toast";

const CouponUsage = (props) => {
    const { showModal, setReload, setMessage,couponUsage,setCouponUsage } = props;
    // const context = useContext(AppContext);
  const { selectedFacility, selectedArena, user } = useContext(AppContext);

    const [isLoading, setisLoading] = useState(true);
    const [couponTransactions, setCouponTransactions] = useState([]);
    const [showMessage, setShowMessage] = useState(false);
    const [messageContent, setMessageContent] = useState({});
  const [loadData, setLoadData] = useState(false);
  const [showDetails, setShowDetails] = useState(false);

    useEffect(() => {
        console.log('couponUsage',couponUsage);
        console.log('selectedFacility',selectedFacility);
        setisLoading(true);
        loadCouponTransactions(
            selectedFacility,
            selectedArena.arenaId,
            couponUsage.data.couponId,
          ).then((res) => {
            let transactionData = res.data.data;
            //  console.log('transactionData',transactionData);
             setCouponTransactions(transactionData);
          });
    }, [ couponUsage])

    const openTransactionDetail = (data) => () => {
        setShowDetails(data);
      };

      function firestoreTimestampToFormattedDate(timestamp) {
        const milliseconds = timestamp._seconds * 1000 + timestamp._nanoseconds / 1000000;
        return moment(milliseconds).format('DD MMM YYYY');
    }

    



    const modalContent = (
        <div style={{ padding: "16px" }}>
        <Toast
          showMessage={showMessage}
          setShowMessage={setShowMessage}
          message={messageContent.message}
          messageHeader={messageContent.header}
          color={messageContent.color}
        />
        {/* <div style={{ paddingBottom: "16px" }}>
          <div className='dashboard'>
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
              }}
            >
              <div>
                <span >Transaction Report </span>
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                    paddingRight: "10px",
                  }}
                >
                
                </span>
              </div>
              <div style={{ alignItems: "center" }}>
                <span><span>
                 
                </span>
                 
                </span>
              </div>
            </div>
          </div>
        </div> */}
  
         
        {!!showDetails ? (
          <TransactionDetails
            selectedData={showDetails}
            setShowDetails={setShowDetails}
            role={user.role}
            setMessage={setShowMessage}
            setMessageContent={setMessageContent}
            isLoading={setLoadData}
          />
        ) : null}
        {loadData ? (
          <GenericLoader />
        ) : couponTransactions.length === 0 ? (
          "No data available"
        ) : (
          <div>
            
            <Table celled padded>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>S.No.</Table.HeaderCell>
                  <Table.HeaderCell>Date</Table.HeaderCell>
                  <Table.HeaderCell>Time</Table.HeaderCell>
                  <Table.HeaderCell>Customer Name</Table.HeaderCell>
                  <Table.HeaderCell>Customer Mobile</Table.HeaderCell>
                  <Table.HeaderCell>Total Amount</Table.HeaderCell>
                  <Table.HeaderCell>Discount</Table.HeaderCell>
                  <Table.HeaderCell>Transaction ID</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {couponTransactions.map((x,index) => {
                  console.log(x.id);
                  console.log(x);
                  console.log("-----------");
                  return (
                    <Table.Row
                      style={
                        x.amountDue ? { backgroundColor: colors.red["50"] } : {}
                      }
                    >
                      <Table.Cell>
                        <div>
                         {index+1}
                        </div>
                      </Table.Cell>
                      <Table.Cell>
                    {  convertFirstoreDate(x.createdDate)}
                      </Table.Cell>
                      <Table.Cell>
                        {x.createdTime}/- 
                      </Table.Cell>
                      <Table.Cell>
                        <div>{x.userName}</div>
                        
                      </Table.Cell>
                      <Table.Cell>
                        <div>{x.userMobileNumber}</div>
                        
                      </Table.Cell>
                      <Table.Cell>
                        Rs.{x.totalAmount}/- 
                      </Table.Cell>
                      
                       
                      <Table.Cell>{x.discountAmount || ""}</Table.Cell>
                      
                     
                      <Table.Cell 
                      onClick={openTransactionDetail(x)
                      }
                      >
                        <div
                          style={{
                            textDecoration: "underline",
                            color: colors.blue[500],
                          }}
                        >
                          {x.id}
                        </div>
                       
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </div>
        )}
      </div>
    );

    const renderAction = () => {
        return (
            <React.Fragment>
                <Button onClick={ ()=>{setCouponUsage()}} className='ui button'>Close</Button>

                {/* <Button
                    //  disabled={!isSubmitAllowed}
                    // primary onClick={edit ?updateCouponFunction:addCouponFunction}
                    >  Submit</Button> */}
            </React.Fragment>
        );
    };

   
    
    return (
        <>
            <ModalPortal
                title={ 'Coupon Usage'}
                content={modalContent}
                 action={renderAction()}
                onDismiss={() => showModal(false)}
            />
        </>

    );


}

export default CouponUsage;