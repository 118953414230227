import { tokenAxiosInstance } from "./ApiClient";
import { API_URLS, getURLWithQueryParams } from "./url";

/**
 *
 * @param {string} facilityId
 * @param {object} postBody will be {sport,
  products,
  userName,
  mobileNumber,
  discount,
  receiptNumber,
  paymentInfo}
 */
export const rentProducts = (facilityId, arenaId, postBody) => {
  const url = getURLWithQueryParams(API_URLS.SELL_PRODUCTS, null, {
    facilityId,
    arenaId,
  });
  return tokenAxiosInstance.post(url, postBody);
};
export const complimentaryProduct = (facilityId, arenaId, postBody) => {
  const url = getURLWithQueryParams(API_URLS.COMPLIMENTARY_PRODUCTS, null, {
    facilityId,
    arenaId,
  });
  return tokenAxiosInstance.post(url, postBody);
};


export const sellCafeProducts = (facilityId, arenaId, postBody) => {
  const url = getURLWithQueryParams(API_URLS.SELL_CAFE_PRODUCTS, null, {
    facilityId,
    arenaId,
  });
  return tokenAxiosInstance.post(url, postBody);
};

export const getProductHistory = (facilityId, arenaId, productId) => {
  const url = getURLWithQueryParams(API_URLS.PRODUCT_HISTORY, null, {
    facilityId,
    arenaId,
    productId,
  });
  return tokenAxiosInstance.get(url);
};
export const getAcademyProductHistory = (facilityId, arenaId, sportId,productId) => {
  const url = getURLWithQueryParams(API_URLS.ACADEMY_PRODUCT_HISTORY, null, {
    facilityId,
    arenaId,
    sportId,
    productId,
  });
  return tokenAxiosInstance.get(url);
};
export const getCafeProductHistory = (facilityId, arenaId, productId) => {
  const url = getURLWithQueryParams(API_URLS.CAFE_PRODUCT_HISTORY, null, {
    facilityId,
    arenaId,
    productId,
  });
  return tokenAxiosInstance.get(url);
};


export const getCafeProductCategories = () => {
  const url = getURLWithQueryParams(API_URLS.CAFE_PRODUCT_CATEGORIES, null, {});
  return tokenAxiosInstance.get(url);
};

export const getProductsFromCategorySubCategory = (facilityId, cat, subCat, type) => {
  console.log(', type ', type);
  const url = getURLWithQueryParams(API_URLS.PRODUCT_AS_PER_CATEGORIES, null, {
    facilityId, cat, subCat, type
  });
  return tokenAxiosInstance.get(url);
};

export const getAllCategories = (type)=> {
  const url = getURLWithQueryParams(API_URLS.PRODUCT_CATEGORIES, null, {type});
  return tokenAxiosInstance.get(url);
};

export const getStockMaster = (facilityId, ) => {
  const url = getURLWithQueryParams(API_URLS.STOCK_MASTER, null, {
    facilityId,
  });
  return tokenAxiosInstance.get(url);
};

export const getStockRegister = (facilityId, arenaId) => {
  const url = getURLWithQueryParams(API_URLS.PRODUCT_REGISTER, null, {
    facilityId,arenaId
  });
  return tokenAxiosInstance.get(url);
};

export const getCafeStockMaster = (facilityId, ) => {
  const url = getURLWithQueryParams(API_URLS.CAFE_STOCK_MASTER, null, {
    facilityId,
  });
  return tokenAxiosInstance.get(url);
};

export const getCafeStockRegister = (facilityId, arenaId) => {
  const url = getURLWithQueryParams(API_URLS.CAFE_PRODUCT_REGISTER, null, {
    facilityId,arenaId
  });
  return tokenAxiosInstance.get(url);
};

export const getAcademyStockRegister = (facilityId, arenaId, sportId) => {
  const url = getURLWithQueryParams(API_URLS.ACADEMY_PRODUCT_REGISTER, null, {
    facilityId,arenaId, sportId
  });
  return tokenAxiosInstance.get(url);
};

export const saveProductUpdatedData = (
  facilityId,
  arenaId,
  productId,
  postBody
) => {
  const url = getURLWithQueryParams(API_URLS.UPDATE_PRODUCT, null, {
    facilityId,
    arenaId,
    productId,
  });
  return tokenAxiosInstance.post(url, postBody);
};

export const addProduct = (facilityId, arenaId, postBody) => {
  const url = getURLWithQueryParams(API_URLS.ADD_PRODUCT, null, {
    facilityId,
    arenaId,
  });
  return tokenAxiosInstance.post(url, postBody);
};

export const addCategory = ( postBody) => {
  const url = getURLWithQueryParams(API_URLS.ADD_CATEGORY);
  return tokenAxiosInstance.post(url, postBody);
};


export const addProductsToMaster = (facilityId, postBody) => {
  const url = getURLWithQueryParams(API_URLS.ADD_PRODUCT_TO_MASTER, null, {
    facilityId,
  });
  return tokenAxiosInstance.post(url, postBody);
};

export const addProductsToCafeMaster = (facilityId, postBody) => {
  const url = getURLWithQueryParams(API_URLS.ADD_PRODUCT_TO_CAFE_MASTER, null, {
    facilityId,
  });
  return tokenAxiosInstance.post(url, postBody);
};

export const addProductsToRegister = (facilityId,arenaId, postBody) => {
  const url = getURLWithQueryParams(API_URLS.ADD_PRODUCT_TO_REGISTER, null, {
    facilityId,arenaId
  });
  return tokenAxiosInstance.post(url, postBody);
};

export const addProductsToCafeRegister = (facilityId,arenaId, postBody) => {
  console.log('this is called');
  const url = getURLWithQueryParams(API_URLS.ADD_PRODUCT_TO_CAFE_REGISTER, null, {
    facilityId,arenaId
  });
  return tokenAxiosInstance.post(url, postBody);
};

export const discardProductsFromRegister = (facilityId,arenaId, postBody) => {
  const url = getURLWithQueryParams(API_URLS.DISCARD_PRODUCT_FROM_REGISTER, null, {
    facilityId,arenaId
  });
  return tokenAxiosInstance.post(url, postBody);
};

export const updatePriceOfProducts = (facilityId,arenaId, postBody) => {
  const url = getURLWithQueryParams(API_URLS.UPDATE_PRODUCT__PRICE_IN_REGISTER, null, {
    facilityId,arenaId
  });
  return tokenAxiosInstance.post(url, postBody);
};


export const discardProductsFromCafeRegister = (facilityId,arenaId, postBody) => {
  const url = getURLWithQueryParams(API_URLS.DISCARD_PRODUCT_FROM_CAFE_REGISTER, null, {
    facilityId,arenaId
  });
  return tokenAxiosInstance.post(url, postBody);
};


export const discardProductsFromAcademyRegister = (facilityId,arenaId, sportId, postBody) => {
  const url = getURLWithQueryParams(API_URLS.DISCARD_PRODUCT_FROM_ACADEMY_REGISTER, null, {
    facilityId,arenaId, sportId
  });
  return tokenAxiosInstance.post(url, postBody);
};

export const assignProductToAcademy = (facilityId,arenaId, postBody) => {
  const url = getURLWithQueryParams(API_URLS.ASSIGN_PRODUCT_TO_ACADEMY, null, {
    facilityId,arenaId
  });
  return tokenAxiosInstance.post(url, postBody);
};

export const allotProductToStudents = (postBody) => {
  const url = getURLWithQueryParams(API_URLS.ALLOT_PRODUCTS_TO_STUDENT);
  return tokenAxiosInstance.post(url, postBody);
};


export const deleteProductsFromMaster = (facilityId, postBody) => {
  const url = getURLWithQueryParams(API_URLS.DELETE_PRODUCT_TO_MASTER, null, {
      facilityId,
    });
    return tokenAxiosInstance.delete(url, {data : postBody});
}

export const deleteProductsFromCafeMaster = (facilityId, postBody) => {
  const url = getURLWithQueryParams(API_URLS.DELETE_PRODUCT_FROM_CAFE_MASTER, null, {
      facilityId,
    });
    return tokenAxiosInstance.delete(url, {data : postBody});
}


export const deleteCategoryFromDB = ( postBody) => {
  const url = getURLWithQueryParams(API_URLS.DELETE_CATEGORY);
    return tokenAxiosInstance.delete(url, {data : postBody});
}

export const editProductInMaster = (facilityId, postBody) => {
  const url = getURLWithQueryParams(API_URLS.EDIT_PRODUCT_IN_MASTER, null, {
      facilityId,
    });
    return tokenAxiosInstance.patch(url, postBody);
}

export const editProductInCafeMaster = (facilityId, postBody) => {
  const url = getURLWithQueryParams(API_URLS.EDIT_PRODUCT_IN_CAFE_MASTER, null, {
      facilityId,
    });
    return tokenAxiosInstance.patch(url, postBody);
}