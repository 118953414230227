import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../AppContext";
import "./signin.scss";
import { Icon, Button } from "semantic-ui-react";

import { login, getLoggedUserDetails } from "../../apiclients/AuthApiClient";
import Toast from "../generic/Toast";
import { ROLES } from "../../utils/constants";

const SignIn = (props) => {
  const { history } = props;
  const context = useContext(AppContext);

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(true);
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const navigateOnSuccess = (userDetails) => {
    setLoading(false);
    context.dispatch({ type: "addUser", payload: userDetails });
    if(userDetails.role === ROLES.COACH){
      history.push("/extranet/attendanceManagement")
    } else if( userDetails.role === ROLES.AUDITOR ){
      history.push("/extranet/transactionReports");
    } else{
      history.push("/extranet/dashboard");
    }
    // userDetails.role === ROLES.COACH ? history.push("/extranet/attendanceManagement"): history.push("/extranet/dashboard");
  };

  useEffect(() => {
    getLoggedUserDetails()
      .then(navigateOnSuccess)
      .catch(() => setLoading(false));
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  const signInWithEmailAndPasswordHandler = (event) => {
    event.preventDefault();
    setLoading(true);
    login(email, password)
      .then(navigateOnSuccess)
      .catch((error) => {
        setLoading(false);
        setError("Incorrect username or password");
        setTimeout(() => {
          setError("");
        }, 4000);
        console.error("Error signing in with password and email", error);
      });
  };

  const onChangeHandler = (event) => {
    const { name, value } = event.currentTarget;
    if (name === "userEmail") {
      setEmail(value);
    } else if (name === "userPassword") {
      setPassword(value);
    }
  };

  return (
    <>
      {error && (
        <Toast
          color={"red"}
          showMessage={true}
          setShowMessage={() => setError()}
          messageHeader='Login Failed'
          message={error}
        />
      )}
      <div className='signin'>
        <div className='session'>
          <div className='left-image'>
            <img src={require("../../images/favicon 6.ico")} alt='' />
          </div>
          <form className='sign-in-form log-in' autocomplete='off'>
            <div className='heading-play-all'>
              Time for <span>Play All</span>
            </div>
            <p>Welcome back! Log in to your account to view today's clients:</p>
            <div className='floating-label'>
              <input
                className='input-login'
                type='email'
                name='userEmail'
                value={email}
                placeholder='Enter your email'
                id='userEmail'
                onChange={(event) => onChangeHandler(event)}
              />
              <label className='form-label' for='email'>
                Email:
              </label>
              <div className='icon'>
                <Icon disabled name='mail' />
              </div>
            </div>
            <div className='floating-label'>
              <input
                className='input-login'
                type='password'
                name='userPassword'
                value={password}
                placeholder='Password'
                id='userPassword'
                onChange={(event) => onChangeHandler(event)}
              />
              <label className='form-label' for='password'>
                Password:
              </label>
              <div className='icon'>
                <Icon disabled name='lock' />
              </div>
            </div>
            <Button
              id='login-button'
              type='submit'
              onClick={(event) => {
                signInWithEmailAndPasswordHandler(event);
              }}
              loading={loading}
              disabled={loading}
            >
              Log in
            </Button>
          </form>
        </div>
      </div>
    </>
  );
};
export default SignIn;
