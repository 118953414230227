import React, { useState } from 'react';
import './CentralPopUp.css'; // Optional for additional styling
import { enquiryForBDSchool } from '../../apiclients/AuthApiClient'
import GenericLoader from "../generic/GenericLoader";

function CentralPopUp(props) {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        mobile: "",
        city: "",
        location: "",
        schoolName: "",
        designation: "",
        remarks: ""

    })
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState("")
    const handleChange = (key, value) => {
        setFormData((prevUser) => ({
            ...prevUser,       // Spread the previous object properties
            [key]: value   // Only update the 'name' property
        }));
    }

    function validateEmail(email) {
        // Regular expression for validating email
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        return regex.test(email);
    }

    function isNumber(value) {
        // Remove leading/trailing spaces and check if the value is a finite number
        return !isNaN(value) && isFinite(value.trim());
      }
    const enquiryForBDSchoolApi = async () => {
        try {
            if (!formData.name) {
                setError("name")
                return
            }
            if (!formData.email || !validateEmail(formData.email)) {
                setError("email")
                return
            }
            if (!formData.mobile || !isNumber(formData.mobile)) {
                setError("mobile")
                return

            }
            if (!formData.city) {
                setError("city")
                return

            }
            if (!formData.location) {
                setError("location")
                return

            }
            if (!formData.schoolName) {
                setError("schoolName")
                return

            }
            if (!formData.designation) {
                setError("designation")
                return

            }
            if (!formData.remarks) {
                setError("remarks")
                return

            }
            setIsLoading(true)
            let data = await enquiryForBDSchool(formData)
            if (data.status === 200) {
                setIsLoading(false)
                props.setSuccessPopUp()
                props.onClose()
            }
            console.log("data", data)
            console.log("formData", formData)
        } catch (error) {
            setIsLoading(false)
            console.log("error in enquiryForBDSchoolApi", error)
        }
    }

    if (isLoading) {
        return (
            <GenericLoader />
        )
    }

    return (
        <>

            {props.isOpen && (   
                <div className="blackContainer"   >
                    <div className="whiteContainer"  >

                        <div className='justifyAlignCenter'>
                            <img
                                alt=''
                                class='logo'
                                src='images/appLogo.png'
                            />
                        </div>
                        <div style={{ padding: "0 2vw 2vw 2vw" }}>
                            <div>
                                <p className='enquiryFormPtag'>ENQUIRY FORM</p>
                                <p style={{ textAlign: "center" }}>FOR SCHOOLS</p>
                            </div>
                            <div style={{ marginTop: "2vh" }}>
                                <input
                                    placeholder='Name'
                                    type="text"
                                    className='inputQueryForm'
                                    value={formData.name}
                                    onChange={(e) => { handleChange("name", e.target.value) }}
                                ></input>
                                {error === "name" ?
                                    <p style={{ marginLeft: "1vw", color: "red" }}>Please enter name</p> : null}
                            </div>
                            <div style={{ marginTop: "2vh" }}>
                                <input
                                    placeholder='Email'
                                    type="text"
                                    className='inputQueryForm'
                                    value={formData.email}
                                    onChange={(e) => { handleChange("email", e.target.value) }}
                                ></input>
                                {error === "email" ?
                                    <p style={{ marginLeft: "1vw", color: "red" }}>Please enter valid email</p> : null}
                            </div>
                            <div style={{ marginTop: "2vh" }}>
                                <input
                                    placeholder='Mobile'
                                    type="text"
                                    className='inputQueryForm'
                                    value={formData.mobile}
                                    onChange={(e) => { handleChange("mobile", e.target.value) }}
                                    max={10}
                                ></input>
                                {error === "mobile" ?
                                    <p style={{ marginLeft: "1vw", color: "red" }}>Please enter mobile</p> : null}
                            </div>
                            <div style={{ marginTop: "2vh" }}>
                                <input
                                    placeholder='City'
                                    type="text"
                                    className='inputQueryForm'
                                    value={formData.city}
                                    onChange={(e) => { handleChange("city", e.target.value) }}
                                ></input>
                                 {error === "city" ?
                                    <p style={{ marginLeft: "1vw", color: "red" }}>Please enter city</p> : null}
                            </div>
                            <div style={{ marginTop: "2vh" }}>
                                <input
                                    placeholder='Location'
                                    type="text"
                                    className='inputQueryForm'
                                    value={formData.location}
                                    onChange={(e) => { handleChange("location", e.target.value) }}
                                ></input>
                                 {error === "location" ?
                                    <p style={{ marginLeft: "1vw", color: "red" }}>Please enter location</p> : null}
                            </div>
                            <div style={{ marginTop: "2vh" }}>
                                <input
                                    placeholder='School Name'
                                    type="text"
                                    className='inputQueryForm'
                                    value={formData.schoolName}
                                    onChange={(e) => { handleChange("schoolName", e.target.value) }}
                                ></input>
                                 {error === "schoolName" ?
                                    <p style={{ marginLeft: "1vw", color: "red" }}>Please enter school name</p> : null}
                            </div>
                            <div style={{ marginTop: "2vh" }}>
                                <input
                                    placeholder='Designation'
                                    type="text"
                                    className='inputQueryForm'
                                    value={formData.designation}
                                    onChange={(e) => { handleChange("designation", e.target.value) }}

                                ></input>
                                  {error === "designation" ?
                                    <p style={{ marginLeft: "1vw", color: "red" }}>Please enter designation</p> : null}
                            </div>
                            <div style={{ marginTop: "2vh" }}>
                                <input
                                    placeholder='Remarks'

                                    type="text"
                                    className='inputQueryForm'
                                    value={formData.remarks}
                                    onChange={(e) => { handleChange("remarks", e.target.value) }}
                                ></input>
                                  {error === "remarks" ?
                                    <p style={{ marginLeft: "1vw", color: "red" }}>Please enter remarks</p> : null}
                            </div>
                            <div className='submitView'>
                                <button
                                    type="button"
                                    className='submitButton'
                                    onClick={() => { enquiryForBDSchoolApi() }}
                                >Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

        </>
    );
}

export default CentralPopUp;
