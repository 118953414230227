export let BASE_URL = "/api";
//export let BASE_URL = "https://playall-test-backend.herokuapp.com/api";
// export let BASE_URL = "http://localhost:8000/api";

export const API_URLS = {
  LOGIN: BASE_URL + "/signin",
  USER: BASE_URL + "/user",
  ENQUIRY_FOR_BD_SCHOOL:BASE_URL + "/enquiryForBDSchool",
  USER_DETAIL: BASE_URL + "/facility/:facilityId/customerDetail",
  MEMBER_DETAIL: BASE_URL + "/facility/:facilityId/arena/:arenaId/sport/:sportId/memberDetail",
  LOGGED_USER: BASE_URL + "/loggedInUser",
  RECEIPT_INFO: BASE_URL + "/facility/:facilityId/latestReceipt",
  // assetlink
  ASSET_LINK: "/.well-known/assetlinks.json",
  ASSET_APPLE_LINK: "/.well-known/apple-app-site-association",

  // Facility related
  FACILITY_DATA: BASE_URL + "/facility",
  FACILITY_BOOKINGS: BASE_URL + "/facility/slots",
  FACILITY_POLICY: BASE_URL + "/facility/:facilityId/policy/:arenaId",
  FACILITY_ARENA_SPORTS:
    BASE_URL + "/facility/:facilityId/arenas/:arenaId/sports",
  FACILITY_ARENA_ALL_SPORTS:
    BASE_URL + "/facility/:facilityId/arenas/:arenaId/allSports",
  FACILITY_ARENA_ACADEMY_SPORTS:
    BASE_URL + "/facility/:facilityId/arenas/:arenaId/academySports",
  FACILITY_ARENA_ACADEMY_STUDENTS:
      BASE_URL + "/facility/:facilityId/arenas/:arenaId/sports/:sportId/studentList",
  FACILITY_ARENA_ACADEMY_STUDENT_DETAIL:
          BASE_URL + "/facility/:facilityId/arenas/:arenaId/sports/:sportId/student/:studentId/details",

  SPORT_PRICING:
    BASE_URL + "/facility/:facilityId/arenas/:arenaId/pricing/:sportId",

  // Booking related
  CREATE_BOOKING: BASE_URL + "/facility/:facilityId/booking",
  POST_FLEXI_MEMBERSHIP_BOOKING: BASE_URL + "/booking/postFlexiMemberBooking",
  CANCEL_BOOKING: BASE_URL + "/facility/:facilityId/cancelBooking/:arenaId",
  CHANGE_PAYMENT_MODE:
    BASE_URL + "/facility/:facilityId/changePaymentMode/:arenaId/booking/:bookingId",
  BOOKING_DETAILS:
    BASE_URL + "/facility/:facilityId/detail/:arenaId/booking/:bookingId",

  // Reschedule
  RESCHEDULE_AVAILABLE_SLOTS:
    BASE_URL + "/facility/:facilityId/rescheduleAvailableSlot/:arenaId",
  RESCHEDULE_BOOKING: BASE_URL + "/facility/:facilityId/reschedule/:arenaId",

  // Extend
  EXTEND_AVAILABLE_COURTS:
    BASE_URL + "/facility/:facilityId/extendAvailableCourts/:arenaId",
  EXTEND_BOOKING: BASE_URL + "/facility/:facilityId/extendBooking/:arenaId",
  PAY_DUE_EXTENSION:
    BASE_URL + "/facility/:facilityId/payDueExtension/:arenaId",

  //release slot
  RELEASE_SLOTS: BASE_URL + '/releaseSlot',

  // Products
  FETCH_PRODUCTS: BASE_URL + "/facility/:facilityId/products/:arenaId",
  FETCH_COMPLIMENTARY_PRODUCTS : BASE_URL + "/facility/:facilityId/complimentaryProducts/:arenaId",
  FETCH_CAFE_PRODUCTS: BASE_URL + "/facility/:facilityId/cafeProducts/:arenaId",
  FETCH_ACADEMY_PRODUCTS: BASE_URL + "/facility/:facilityId/arena/:arenaId/sport/:sportId/academyProducts",
  // RENT_PRODUCTS: BASE_URL + "/facility/:facilityId/rentProducts",
  SELL_PRODUCTS: BASE_URL + "/facility/:facilityId/sellProducts/:arenaId",
  COMPLIMENTARY_PRODUCTS: BASE_URL + "/facility/:facilityId/complimentaryProducts/:arenaId",
  SELL_CAFE_PRODUCTS: BASE_URL + "/facility/:facilityId/sellCafeProducts/:arenaId",
  PRODUCT_CATEGORIES: BASE_URL + "/:type/getCategories",
  CAFE_PRODUCT_CATEGORIES: BASE_URL + "/cafeProductCategories",
  PRODUCT_AS_PER_CATEGORIES: BASE_URL + "/facility/:facilityId/category/:cat/subCategory/:subCat/:type/productAsPerCategories",
  PRODUCT_HISTORY: BASE_URL + "/facility/:facilityId/products/:arenaId/history/:productId",
  CAFE_PRODUCT_HISTORY: BASE_URL + "/facility/:facilityId/products/:arenaId/cafeHistory/:productId",
  ACADEMY_PRODUCT_HISTORY: BASE_URL + "/facility/:facilityId/products/:arenaId/sport/:sportId/academyHistory/:productId",
  UPDATE_PRODUCT: BASE_URL + "/facility/:facilityId/arenas/:arenaId/products/:productId",
  ADD_PRODUCT: BASE_URL + "/facility/:facilityId/arenas/:arenaId/product",
  ADD_CATEGORY: BASE_URL + "/addCategory",
  ADD_PRODUCT_TO_MASTER: BASE_URL + "/facility/:facilityId/productToMaster",
  ADD_PRODUCT_TO_CAFE_MASTER: BASE_URL + "/facility/:facilityId/productToCafeMaster",
  ADD_PRODUCT_TO_REGISTER: BASE_URL + "/facility/:facilityId/arena/:arenaId/productToRegister",
  ADD_PRODUCT_TO_CAFE_REGISTER: BASE_URL + "/facility/:facilityId/arena/:arenaId/productToCafeRegister",
  UPDATE_PRODUCT__PRICE_IN_REGISTER: BASE_URL + "/facility/:facilityId/arena/:arenaId/updatePrice",
  DISCARD_PRODUCT_FROM_REGISTER: BASE_URL + "/facility/:facilityId/arena/:arenaId/discardProductFromRegister",
  DISCARD_PRODUCT_FROM_CAFE_REGISTER: BASE_URL + "/facility/:facilityId/arena/:arenaId/discardProductFromCafeRegister",
  DISCARD_PRODUCT_FROM_ACADEMY_REGISTER: BASE_URL + "/facility/:facilityId/arena/:arenaId/sport/:sportId/discardProductFromAcademyRegister",
  ASSIGN_PRODUCT_TO_ACADEMY : BASE_URL + "/facility/:facilityId/arena/:arenaId/assignProductToAcademy",
  ALLOT_PRODUCTS_TO_STUDENT : BASE_URL + "/allotProductsToStudent",
  STOCK_MASTER: BASE_URL + "/facility/:facilityId/stockMaster",
  CAFE_STOCK_MASTER: BASE_URL + "/facility/:facilityId/cafeStockMaster",
  PRODUCT_REGISTER: BASE_URL + "/facility/:facilityId/arenas/:arenaId/productRegister",
  CAFE_PRODUCT_REGISTER: BASE_URL + "/facility/:facilityId/arenas/:arenaId/cafeProductRegister",
  ACADEMY_PRODUCT_REGISTER: BASE_URL + "/facility/:facilityId/arenas/:arenaId/sport/:sportId/academyProductRegister",
  DELETE_PRODUCT_TO_MASTER: BASE_URL + "/deleteProductFromMaster",
  DELETE_PRODUCT_FROM_CAFE_MASTER: BASE_URL + "/deleteProductFromCafeMaster",
  DELETE_CATEGORY: BASE_URL + "/deleteCategory",
  EDIT_PRODUCT_IN_MASTER: BASE_URL + "/editProductInMaster",
  EDIT_PRODUCT_IN_CAFE_MASTER: BASE_URL + "/editProductInCafeMaster",
  //Reports
  BOOKING_REPORT: BASE_URL + "/facility/:facilityId/transactions/:arenaId",
  COUPON_TRANSACTION: BASE_URL + "/facility/:id/couponTransactions/:arenaId/:couponId",

  OTHER_BOOKING_REPORT: BASE_URL + "/facility/:facilityId/otherTransactions",

  AMOUNT_DUE_REPORT: BASE_URL + "/facility/:facilityId/getAmountDueTransactions/:arenaId",
  ACCOUNTING_REPORT: BASE_URL + "/facility/:facilityId/getAccounts/:arenaId",
  ENQUIRY_REPORT: BASE_URL + "/facility/:facilityId/enquiries",
  BD_REPORT: BASE_URL + "/facility/:facilityId/getBDLeads",
  TRIALS_REPORT: BASE_URL + "/facility/:facilityId/trials",
  RELEASE_REPORT: BASE_URL + "/facility/:facilityId/release",
  BLOCKING_REPORT: BASE_URL + "/facility/:facilityId/blockingReport",
  DELETE_TRANSACTION: BASE_URL + "/deleteTransaction",
  EMPLOYEES_ATTENDANCE:
    BASE_URL + "/facility/:facilityId/employeesAttendance/:arenaId",
  GET_RECEIPT_NUMBER: BASE_URL + "/facility/:facilityId/:arenaId/getReceiptNumber",
  EDIT_RECEIPT_NUMBER: BASE_URL + "/editReceiptNumber",
  CHANGE_PAYMENT_MODE: BASE_URL + "/changePaymentMode",
  GET_MONTHLY_DATA_BEFORE_CURRENT_MONTH: BASE_URL + "/monthlyDataBeforeCurrentMonth/:facilityId/:arenaId/:sportId/:startDate",
  
  // Bulk booking
  GET_BULK_BOOKING: BASE_URL + "/facility/:facilityId/bulkBookings/:arenaId",
  CHECK_AVAILABILITY_BULK_BOOKING:
    BASE_URL + "/facility/:facilityId/checkAvailability/:arenaId",
  GET_BULK_BOOKING_DETAIL:
    BASE_URL + "/facility/:facilityId/bulkBookings/:arenaId/detail/:id",
  CREATE_BULK_BOOKING: BASE_URL + "/facility/:facilityId/bulkBooking/:arenaId",
  UPDATE_BULK_PAYMENT:
    BASE_URL + "/facility/:facilityId/bulkBooking/:arenaId/payment",
  CANCEL_BULK_BOOKING:
    BASE_URL + "/facility/:facilityId/bulkBooking/:arenaId/cancel",
  CANCEL_BULK_BOOKING_AMOUNT:
    BASE_URL + "/facility/:facilityId/bulkBooking/:arenaId/cancelAmount",

  // Blocking
  CREATE_BLOCKING: BASE_URL + "/facility/:facilityId/blocking/:arenaId",
  RELEASE_BLOCKING: BASE_URL + "/facility/:facilityId/releaseBlocking/:arenaId",
  GET_BLOCKING: BASE_URL + "/facility/:facilityId/blockings/:arenaId",
  CANCEL_BLOCKING: BASE_URL + "/facility/:facilityId/blocking/:arenaId/cancel",
  GET_BLOCKING_DETAIL:
    BASE_URL + "/facility/:facilityId/blocking/:arenaId/detail/:id",

  // Policies
  GET_POLICIES: BASE_URL + "/facility/:facilityId/policies/:arenaId",
  UPDATE_POLICIES: BASE_URL + "/facility/:facilityId/updatePolicies/:arenaId",

  // Membership
  GET_PACKAGES: BASE_URL + "/facility/:facilityId/getPackage/:arenaId",
  CREATE_PACKAGE: BASE_URL + "/facility/:facilityId/createPackage/:arenaId",
  CREATE_FLEXI_PACKAGE: BASE_URL + "/facility/:facilityId/createFlexiPackage/:arenaId",
  DELETE_PACKAGE:
    BASE_URL + "/facility/:facilityId/packages/:arenaId/deactivate",
  DELETE_FLEXI_PACKAGE:
    BASE_URL + "/facility/:facilityId/flexiPackages/:arenaId/deactivate",
  GET_MEMBERSHIPS:
    BASE_URL + "/facility/:facilityId/arenas/:arenaId/membership",
  GET_FLEXI_MEMBERSHIPS:
    BASE_URL + "/facility/:facilityId/arenas/:arenaId/getFlexiMembership",
 
    UPDATE_PAYMENT_MEMBERSHIP:
    BASE_URL +
    "/facility/:facilityId/payment/:arenaId/membership/:membershipId",
  CANCEL_MEMBERSHIP:
    BASE_URL + "/facility/:facilityId/cancellation/:arenaId/membership",
    DEACTIVATE_FLEXI_MEMBERSHIP:
      BASE_URL + "/facility/:facilityId/cancellation/:arenaId/deactivateFlexiMembership",
  CANCEL_AMOUNT_MEMBERSHIP:
    BASE_URL +
    "/facility/:facilityId/cancellation/:arenaId/membership/:membershipId/amount",
  CHECK_AVAILABILITY_MEMBERSHIP:
    BASE_URL + "/facility/:facilityId/checkAvailabilityPackage/:arenaId",
  CREATE_MEMBERSHIP:
    BASE_URL + "/facility/:facilityId/arena/:arenaId/membership",
  ADD_FLEXI_MEMBERSHIP:
      BASE_URL + "/facility/:facilityId/arena/:arenaId/addFlexiMembership",

  // Wallet
  GET_WALLET_PACKAGES: BASE_URL + "/facility/:facilityId/arena/:arenaId/getWalletPackages",
  GET_WALLET_DETAILS: BASE_URL + "/getWalletDetails",
  ADD_WALLET_PACKAGES: BASE_URL + "/addWalletPackage",
  ADD_WALLET_PACKAGES: BASE_URL + "/addWalletPackage",
  EDIT_WALLET_PACKAGES: BASE_URL + "/editWalletPackage",
  DISABLE_WALLET_PACKAGES: BASE_URL + "/disableWalletPackage",
  DELETE_WALLET_PACKAGES: BASE_URL + "/deleteMembershipPackage",
  REDUCE_WALLET_CREDITS: BASE_URL + "/reduceCreditOfUser",
  ASSIGN_WALLET_CREDITS: BASE_URL + "/assignCreditToUser",
  ASSIGN_SPECIAL_CREDITS: BASE_URL + "/assignSpecialCreditToUser",
  GET_USER_WALLET_DETAILS_WEB: BASE_URL+"/:facilityId/:mobile/:expired/:arenaId/getUserWalletsDetails",
  GET_WALLET_TRANSACTION: BASE_URL+"/:facilityId/:mobile/:walletId/getWalletTransactions",

  // Coupons
  ADD_COUPON: BASE_URL + "/createCoupons",
  UPDATE_COUPON: BASE_URL + "/updateCoupons",
  GET_COUPONS: BASE_URL + "/getCoupons",
  DISABLE_COUPON: BASE_URL + "/disableCoupons",

  //User Management
  GET_USER_DETAILS: BASE_URL + "/roles",
  EDIT_USER: BASE_URL + "/updateUser",
  ADD_USER: BASE_URL + "/createUser",
  DELETE_USER: BASE_URL + "/deleteUser",
  RESET_PASSWORD: BASE_URL + "/resetPassword",
  ALLOW_CHECKIN: BASE_URL + "/removeDevice",

  // Analytics
  GET_OCCUPANCY_ANALYTICS_DATA: BASE_URL + '/occupancyAnalytics/:facilityId/:arenaId/:sportId',
  GET_WEEKLY_ANALYTICS_DATA : BASE_URL + '/weeklyData/:facilityId',
  GET_MONTHLY_ANALYTICS_DATA : BASE_URL + '/monthlyData/:facilityId',
  SLOT_WISE_OCCUPANCY_DATA: BASE_URL + '/occupancyAnalyticsWithinARange/:facilityId/:arenaId/:sportId/:daysOfWeek',
  GET_ACADEMY_ANALYTICS_DATA: BASE_URL + '/academyAnalytics/:facilityId/:arenaId/:sportId',
  GET_TRANSACTION_ANALYTICS_DATA: BASE_URL + '/transactionAnalytics/:facilityId/:arenaId/:sportId',
  DATE_WISE_TRANSACTION_DATA: BASE_URL + '/transactionAnalyticsWithinARange/:facilityId/:arenaId/:sportId',
  GET_LEAD_ANALYTICS_DATA: BASE_URL + '/leadAnalytics/:facilityId/:arenaId',

  // Academy
  ///// Batches
  GET_BATCHES_DATA: BASE_URL + '/getBatches/:facilityId/:arenaId/:sportId',
  EDIT_BATCH: BASE_URL + "/editBatch",
  ADD_BATCH: BASE_URL + "/addBatch",
  DELETE_BATCH: BASE_URL + "/deleteBatch",

  ///// student
  GET_STUDENTS_DATA: BASE_URL + '/getStudentsData/:facilityId/:arenaId/:sportId/:type',
  ENROLL_STUDENT: BASE_URL + '/enrollStudent',
  ADD_PAYMENTS: BASE_URL + '/addPayments',
  DELETE_STUDENT: BASE_URL + '/deleteStudent',
  GET_DATA_FOR_EXISTING_PARENT: BASE_URL + '/getDataForExistingParent/:facilityId/:arenaId/:sportId/:mobile',

  ///// attendnace
  GET_ATTENDANCE_DATA: BASE_URL + '/getAttendanceData/:facilityId/:arenaId/:sportId',
  MARK_ATTENDANCE: BASE_URL + '/markAttendance',

  ///// trial
  ADD_TRIAL: BASE_URL + '/addTrial',
  EDIT_TRIAL: BASE_URL + '/editTrial',
  GET_TRIALS_DATA: BASE_URL + '/getTrialsData/:facilityId/:arenaId/:sportId/:type',
  NO_SHOW_TRIAL: BASE_URL + '/markNoShow',
  LOST_LEAD_TRIAL: BASE_URL + '/markLostLead',

  // Accounts
  ///// Expenses
  GET_EXPENSES_DATA: BASE_URL + '/getExpenses/:facilityId/:arenaId',
  ADD_EXPENSES: BASE_URL + '/addExpenses',
  DELETE_EXPENSE_OR_RECEIPT: BASE_URL + "/deleteExpensesOrReceipts",
  ///// Receipt
  GET_RECEIPT_DATA: BASE_URL + '/getReceipt/:facilityId/:arenaId',
  ADD_RECEIPT: BASE_URL + '/addReceipt',
  ///// CashBook
  GET_CASH_BOOK_DATA: BASE_URL + '/getCashBook/:facilityId/:arenaId/:date',
  GET_NON_CASH_BOOK_DATA: BASE_URL + '/getNonCashBook/:facilityId/:arenaId/:date/:type',
  ///// GST and commission

  UPDATE_GST: BASE_URL + '/updateGstDetails',
  UPDATE_COMMISSION: BASE_URL + '/updateBookingCommission',

  // Customer
  ///// leads
  GET_ACTIVE_LEADS: BASE_URL + '/getActiveLeads/:facilityId/:arenaId',

};

export function getURL(key, params = {}) {
  let url = API_URLS[key] || API_URLS[key] || "";
  Object.keys(params).forEach(function (key) {
    url = url.replace(":" + key, params[key]);
  });
}

export function getURLWithQueryParams(url, queryParams = {}, params = {}) {
  const queryParamsToAppend = getQueryParamsForUrl(queryParams);
  let queryUrl = url + queryParamsToAppend;
  Object.keys(params).forEach(function (key) {
    queryUrl = queryUrl.replace(":" + key, params[key]);
  });
  return queryUrl;
}

const getQueryParamsForUrl = (params) => {
  let queryParams = "?";
  for (let key in params) {
    if (queryParams !== "?") {
      queryParams += "&";
    }
    if (params[key] !== null) {
      queryParams += key + "=" + params[key];
    }
  }
  return queryParams === "?" ? "" : queryParams;
};
