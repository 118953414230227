import React, { useContext, useState, useEffect } from "react";
import GenericLoader from "../generic/GenericLoader";
import { Tab, Icon, Table, Button } from "semantic-ui-react";
import { COUPON_TYPE, ROLES } from "../../utils/constants";
import { AppContext } from "../../AppContext";
import Toast from "../generic/Toast";
import { colors } from "@material-ui/core";
import AddCoupons from "./AddCoupons";
import { getAllCoupons, disableCoupon } from "../../apiclients/WalletApiClient";
import { convertFirstoreDate } from "../../utils/helperFunctions";
import { set } from "react-ga";
import moment from 'moment';
import CouponUsage from "./CouponUsage";
function firestoreTimestampToFormattedDate(timestamp) {
    const milliseconds = timestamp._seconds * 1000 + timestamp._nanoseconds / 1000000;
    return moment(milliseconds).format('DD MMM YYYY');
}

const CouponTable = ({
    couponsData,
    disableCouponFunction,
    setEditdata,
    couponInActive,
    setCouponUsage
}) => {
    return (
        <>
            <Table celled padded>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>S.No</Table.HeaderCell>
                        <Table.HeaderCell>Coupon Name</Table.HeaderCell>
                        <Table.HeaderCell>Description</Table.HeaderCell>
                        <Table.HeaderCell>Arenas</Table.HeaderCell>
                        <Table.HeaderCell>Sports</Table.HeaderCell>
                        <Table.HeaderCell>Discount</Table.HeaderCell>
                        <Table.HeaderCell>Maximum Disc./coupon</Table.HeaderCell>
                        <Table.HeaderCell>Users</Table.HeaderCell>
                        <Table.HeaderCell>Visible</Table.HeaderCell>
                        <Table.HeaderCell>Total Usage</Table.HeaderCell>
                        <Table.HeaderCell>Available Usage</Table.HeaderCell>
                        <Table.HeaderCell>Usage/ user</Table.HeaderCell>
                        <Table.HeaderCell>Start Date</Table.HeaderCell>
                        <Table.HeaderCell>End Date</Table.HeaderCell>
                        <Table.HeaderCell>New Users</Table.HeaderCell>
                        <Table.HeaderCell>Created Date</Table.HeaderCell>
                        <Table.HeaderCell>Created By</Table.HeaderCell>
                        {!couponInActive && <Table.HeaderCell>Action</Table.HeaderCell>}

                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {couponsData && couponsData.map((x, i) => {
                        console.log("x", x)
                        return (
                            <Table.Row key={i}>
                                <Table.Cell>{i + 1}</Table.Cell>
                                <Table.Cell>{x.couponName}</Table.Cell>
                                <Table.Cell>{x.description}</Table.Cell>
                                <Table.Cell>{x.arenas.map(x => x.arenaName + ', ')}</Table.Cell>
                                <Table.Cell>{x.sports.map(x => x.sportName + ', ')}</Table.Cell>
                                <Table.Cell>{x.isItPercentage ? x.percentageDiscount + " %" : "Rs. " + x.amountDiscount}</Table.Cell>
                                <Table.Cell>{x.maxDiscount ? "Rs. " + x.maxDiscount : "Rs. " + x.amountDiscount}</Table.Cell>
                                <Table.Cell>{x.users ? x.users.map(x => x + ', ') : 'All Users'}</Table.Cell>
                                <Table.Cell>{x.isVisible ? "Visible" : "Hidden"}</Table.Cell>
                                <Table.Cell>{x.isUsageLimited ? x.usageLimit : 'Unlimited'}</Table.Cell>
                                <Table.Cell>{x.isUsageLimited ? x.usageAvailable : 'Unlimited'}</Table.Cell>
                                <Table.Cell>{x.isUsageLimitedPerUser ? x.usageLimitPerUser : 'Unlimited'}</Table.Cell>
                                <Table.Cell>{firestoreTimestampToFormattedDate(x.startDate)}</Table.Cell>
                                <Table.Cell>{firestoreTimestampToFormattedDate(x.endDate)}</Table.Cell>
                                <Table.Cell>{x.isNewUser && x.isNewUser ? "New User" : " Old User"}</Table.Cell>
                                <Table.Cell>{firestoreTimestampToFormattedDate(x.createdDate)}</Table.Cell>
                                <Table.Cell>{x.createdBy.userName}</Table.Cell>
                                {!couponInActive &&
                                    <Table.Cell >
                                        <Button
                                            style={{
                                                padding: "8px",
                                                fontSize: "12px",
                                                marginBottom: "8px",
                                                // backgroundColor: colors.orange["800"],
                                                // color: "#fff",
                                            }}
                                            onClick={() => { setCouponUsage({ show: true, data: x }) }}

                                        >
                                            Usages
                                        </Button>
                                        <Button
                                            style={{
                                                padding: "8px",
                                                fontSize: "12px",
                                                marginBottom: "8px",
                                                // backgroundColor: colors.orange["800"],
                                                // color: "#fff",
                                            }}
                                            onClick={() => { setEditdata({ show: true, data: x }) }}
                                        >
                                            Edit
                                        </Button>
                                        <Button
                                            style={{
                                                padding: "8px",
                                                fontSize: "12px",
                                                marginBottom: "8px",

                                            }}
                                            onClick={() => disableCouponFunction(x?.couponId
                                            )}
                                        >
                                            Disable
                                        </Button>


                                    </Table.Cell>}
                            </Table.Row>
                        );
                    })}
                </Table.Body>
            </Table>
        </>
    );
};


const CouponManagement = (props) => {
    const { history } = props;
    const context = useContext(AppContext);
    const [activeIndex, setActiveIndex] = useState(0);
    const [reload, setReload] = useState(false);
    const [isLoading, setisLoading] = useState(true);
    const [showMessage, setShowMessage] = useState(false);
    const [messageContent, setMessageContent] = useState({});
    const [addModal, setAddModal] = useState(false); // to show add modal
    const [editData, setEditdata] = useState({ show: false, data: {} }); // to show add modal
    const [couponsData, setCouponData] = useState([]);
    const [couponUsage, setCouponUsage] = useState({ show: false, data: {} });
    const types = [COUPON_TYPE.active, COUPON_TYPE.inactive, COUPON_TYPE.disabled];

    const disableCouponFunction = (id) => {
        console.log("disableCoupon", id);
        setisLoading(true);

        disableCoupon(
            context.selectedFacility,
            id
        ).then(() => {
            setReload(true);
            //   setisLoading(false);
        }).catch((error) => {
            console.log(error);
            setisLoading(false);
        });
    };

    const panes = [
        {
            menuItem: "Active Coupon",
            render: () => (
                <Tab.Pane className={"tabBox"}>
                    <CouponTable
                        couponsData={couponsData}
                        disableCouponFunction={disableCouponFunction}
                        setEditdata={(data) => { setEditdata(data) }}
                        setCouponUsage={(data) => { setCouponUsage(data) }}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: "Inactive Coupons",
            render: () => (
                <Tab.Pane className={"tabBox"}>
                    <CouponTable
                        couponsData={couponsData}
                        disableCouponFunction={disableCouponFunction}
                        setEditdata={(data) => { setEditdata(data) }}
                        couponInActive={true}
                        setCouponUsage={(data) => { setCouponUsage(data) }}
                    />
                </Tab.Pane>
            ),
        },

        {
            menuItem: "Disabled Coupons",
            render: () => (
                <Tab.Pane className={"tabBox"}>
                    <CouponTable
                        couponsData={couponsData}
                        disableCouponFunction={disableCouponFunction}
                        setEditdata={(data) => { setEditdata(data) }}
                        couponInActive={true}
                        setCouponUsage={(data) => { setCouponUsage(data) }}
                    />
                </Tab.Pane>
            ),
        },

    ];


    useEffect(() => {
        setisLoading(true);
        getAllCoupons(context.selectedFacility, types[activeIndex]).then(
            (response) => {

                setisLoading(false);
                setReload(false);
                if (response.status === 200) {
                    const data = response.data;
                    setCouponData(data);
                }
            }
        ).catch((err) => {
            history.push("/extranet/dashboard");
        });
    }, [activeIndex, reload]);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div style={{ padding: "16px" }}>
            <Toast
                showMessage={showMessage}
                setShowMessage={setShowMessage}
                message={messageContent.message}
                messageHeader={messageContent.header}
                color={messageContent.color}
            />
            <div className='dashboard'>
                <div
                    style={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-end",
                    }}
                >
                    <div>
                        <span>Coupon Management</span>
                    </div>
                    <div style={{ fontSize: "14px" }}>

                        <Button
                            style={{ marginLeft: "20px" }}
                            onClick={() => setAddModal(true)}
                            name={"Add Package"}
                            disabled={context.user.role === ROLES.ADMIN || context.user.role === ROLES.MARKETING_MANAGER ? false : true}
                        >
                            <Icon name={"add"} /> Create a Coupon
                        </Button>

                    </div>
                </div>




            </div>
            <br />
            {isLoading ? (
                <GenericLoader />
            ) : (
                <Tab
                    activeIndex={activeIndex}
                    onTabChange={(e, { activeIndex }) => setActiveIndex(activeIndex)}
                    renderActiveOnly={true}
                    menu={{
                        color: "orange",
                        pointing: true,
                    }}
                    panes={panes}
                />
            )}
            {addModal ? (
                <AddCoupons
                    showModal={setAddModal}
                    setReload={setReload}
                    setMessage={setMessageContent}
                    isLoading={setisLoading}
                />
            ) : null}

            {editData?.show ? (
                <AddCoupons
                    showModal={(value) => setEditdata({ show: false, data: {} })}
                    setReload={setReload}
                    setMessage={setMessageContent}
                    isLoading={setisLoading}
                    data={editData?.data}
                    edit={editData?.show}
                />
            ) : null}
            {couponUsage?.show && <CouponUsage
                showModal={(value) => setCouponData({ show: false, data: {} })}
                setReload={setReload}
                setMessage={setMessageContent}
                isLoading={setisLoading}
                couponUsage={couponUsage}
                setCouponUsage={() => setCouponUsage({ show: false, data: {} })}

                 
            />}

        </div>
    );
};

export default CouponManagement;