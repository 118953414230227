import React, { useContext, useState, useEffect, useRef } from 'react';
import { colors } from "@material-ui/core";
import {
    Button,
    Dropdown,
    Form,
} from "semantic-ui-react";

import ModalPortal from '../../generic/ModalPortal';
import { AppContext } from '../../../AppContext';
import { getAllCategories, editProductInCafeMaster } from '../../../apiclients/ProductApiClient';
import { CATEGORY_TYPE } from '../../../utils/constants';
const EditProductInCafeMaster = (props) => {
    const { showModal, setReload, productData, setMessage, isLoading } = props;
    const context = useContext(AppContext);
    const {arenas} = context;
    const facilityId = context.selectedFacility;
    const rawCategories = useRef([]);
    const [productName, setProductName] = useState(productData.productName);
    const [companyName, setCompanyName] = useState(productData.companyName);
    const [category, setCategory] = useState(productData.category);
    const [subCategory, setSubCategory] = useState(productData.subCategory);
    const [reorderLevel, setReorderLevel] = useState(productData.reorderLevel);
    const [availableForIC, setAvailableForIC] = useState(productData.availableForIC);
    const [availableForSale, setAvailableForSale] = useState(productData.availableForSale);
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [isSubCategoryAvailable, setIsSubCategoryAvailable] = useState(productData.subCategory ? true : false);
    const [cgst, setCgst]=useState(productData.cgst ? productData.cgst : 0 );
    const [igst, setIgst]=useState(productData.igst ? productData.igst : 0 );
    const [sgst, setSgst]=useState(productData.sgst ? productData.sgst : 0 );
    const [hsn, setHsn]=useState(productData.hsn ? productData.hsn : "" );
    const [cess, setCess]=useState(productData.cess ? productData.cess : 0 );

    const isSubmitAllowed = productName && companyName && category && isSubCategoryAvailable ? subCategory : true && (availableForIC || availableForSale)&& ((cgst && sgst)||igst) && hsn;

    useEffect(() => {
        getAllCategories(CATEGORY_TYPE.cafe).then((response) => {
            const availableCategories = response.data.data.categories;
            rawCategories.current = availableCategories;
            const cate = availableCategories.map((x) => {
                return { key: x.id, value: x.name, text: x.name }
            })
            setCategories(cate);
            if (isSubCategoryAvailable) {
                const data = availableCategories.find((categories) =>  categories.name === category);
                const subCate = data.subCategories.map((x) => {
                    return { key: x.id, value: x.name, text: x.name }
                });
                setSubCategories(subCate);
            }
        });

    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const changeCategory = (event, { value }) => {
        const data = rawCategories.current.find((category) => category.name === value);
        if (data.subCategories) {
            setIsSubCategoryAvailable(true);
            const subCate = data.subCategories.map((x) => {
                return { key: x.id, value: x.name, text: x.name }
            });
            setSubCategories(subCate);
        } else {
            setIsSubCategoryAvailable(false);
            setSubCategories([]);
            setSubCategory('');
        }
        setCategory(value);
    };
    const changeSubCategory = (event, { value }) => {
        setSubCategory(value);
    };

    const modalContent = (
        <div>
            <Form>
                <Form.Group widths='equal'>
                    <Form.Field  >
                        <label>Product Name</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='text'
                            value={productName}
                            placeholder='Product Name'
                            onChange={(evt) => setProductName(evt.target.value)}
                        />
                    </Form.Field>
                    <Form.Field  >
                        <label>Company Name</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='text'
                            value={companyName}
                            placeholder='Company Name'
                            onChange={(evt) => setCompanyName(evt.target.value)}
                        />
                    </Form.Field>

                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Field  >

                        <label>Select Category</label>
                        <Dropdown
                            onChange={changeCategory}
                            placeholder='Select...'
                            openOnFocus
                            selection
                            value={category}
                            options={categories}
                            style={{ marginRight: "30px" }}
                        />
                    </Form.Field>

                    {isSubCategoryAvailable ?
                        <Form.Field  >

                            <label>Select Sub-Category</label>
                            <Dropdown
                                onChange={changeSubCategory}
                                placeholder='Select...'
                                openOnFocus
                                selection
                                value={subCategory}
                                options={subCategories}
                                style={{ marginRight: "30px" }}
                            />
                        </Form.Field> : null}
                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Field  >
                        <label>Reorder Level</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='number'
                            value={reorderLevel}
                            placeholder='Reorder Level'
                            onChange={(evt) => setReorderLevel(evt.target.value)}
                        />
                    </Form.Field>
                    <Form.Field  >

                        <label>
                            Avaiable For
                        </label>
                        <Button
                            onClick={() => { setAvailableForSale(!availableForSale) }}
                            style={{
                                marginRight: "10px",
                                backgroundColor: availableForSale
                                    ? colors.orange[700]
                                    : colors.grey[200],
                                color: availableForSale ? colors.blue[50] : colors.black,
                            }}
                        >
                            Sale
                        </Button>
                        <Button
                            onClick={() => { setAvailableForIC(!availableForIC) }}
                            style={{
                                marginRight: "10px",
                                backgroundColor: availableForIC
                                    ? colors.orange[700]
                                    : colors.grey[200],
                                color: availableForIC ? colors.blue[50] : colors.black,
                            }}
                        >
                            Internal Consumption
                        </Button>
                    </Form.Field>
                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Field  >
                        <label>CGST %</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='number'
                            value={cgst*100}
                            placeholder='9'
                            onChange={(evt) => setCgst(evt.target.value/100)}
                        />
                    </Form.Field>
                    <Form.Field  >
                        <label>SGST %</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='number'
                            value={sgst*100}
                            placeholder='9'
                            onChange={(evt) => setSgst(evt.target.value/100)}
                        />
                    </Form.Field>
                    <Form.Field  >
                        <label>IGST %</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='number'
                            value={igst*100}
                            placeholder='0'
                            onChange={(evt) => setIgst(evt.target.value/100)}
                        />
                    </Form.Field>
                    <Form.Field  >
                        <label>HSN</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='number'
                            value={hsn}
                            placeholder='98963'
                            onChange={(evt) => setHsn(evt.target.value)}
                        />
                    </Form.Field>
                    <Form.Field  >
                        <label>Cess %</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='number'
                            value={cess*100}
                            placeholder='2'
                            onChange={(evt) => setCess(evt.target.value/100)}
                        />
                    </Form.Field>

                </Form.Group>
            </Form>
        </div>
    );

    const renderAction = () => {
        return (
            <React.Fragment>
                <Button onClick={() => showModal(false)} className='ui button'>Cancel</Button>

                <Button disabled={!isSubmitAllowed} primary onClick={editProductToMaster}>  Submit</Button>
            </React.Fragment>
        );
    };

    const editProductToMaster = () => {

        showModal(false);
        isLoading(true);

        const postBody = {
            facilityId,
            productName, companyName, category, subCategory, availableForSale, availableForIC,
            id: productData.id, reorderLevel, cgst, sgst, igst,hsn,cess,arenas
        };

        editProductInCafeMaster(
            facilityId,
            postBody
        ).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setMessage({
                    header: "Product Edited to the master",
                    message: "Product has been added edited.",
                    color: "green",
                });
            } else {
                setMessage({
                    header: "Error!",
                    message: response.data.error.message,
                    color: "red",
                });
            }
            setReload(true);
        }).catch((error) => {
            setMessage({
                header: "Error!",
                message: error.message,
                color: "red",
            });
        });
    }
    return (
        <>
            <ModalPortal
                title='Edit Product in Master'
                content={modalContent}
                action={renderAction()}
                onDismiss={() => showModal(false)}
            />
        </>

    );


}

export default EditProductInCafeMaster;