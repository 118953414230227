import React, { useEffect, useContext, useState } from "react";
import {
  Button,
  Icon,
  Table,
} from "semantic-ui-react";
import { colors } from "@material-ui/core";
import Toast from "../../generic/Toast";
import { getCafeStockMaster } from "../../../apiclients/ProductApiClient";
import { AppContext } from "../../../AppContext";
import GenericLoader from "../../generic/GenericLoader";
import AddProductToCafeMaster from "./AddProductToCafeMaster";
import DeleteProductFromCafeMaster from "./DeleteProductFromCafeMaster";
import EditProductInCafeMaster from "./EditProductInCafeMaster";

const CafeStockMaster = (props) => {
  const { history } = props;
  const context = useContext(AppContext);
  const [stockMaster, setStockMaster] = useState([]);

  const [addProduct, setAddProduct] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState();
  const [editModal, setEditModal] = useState(false); // to show edit modal
  const [deleteModal, setDeleteModal] = useState(false); // to show delete modal

  const [loader, setLoader] = useState(false);
  const [reload, setReload] = useState(false);
  const [isLoading, setisLoading] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const [messageContent, setMessageContent] = useState({});

  const loadData = async () => {
    setisLoading(true);
    return getCafeStockMaster(
      context.selectedFacility,
    ).then((response) => {
      const data = response.data;
      setStockMaster(data.data);
      setisLoading(false);
    });
  };

  const availableForName = (productDetails) => {
    let availabilityData = [productDetails.availableForRent ? 'Rent ' : '', productDetails.availableForSale ? 'Sale ' : '', productDetails.availableForAcademy ? 'Academy ' : ''];
    let arrayOfText = [];
    let text = '';
    for (var i = 0; i < availabilityData.length; i++) {
      if (availabilityData[i] !== "") {
        arrayOfText.push(availabilityData[i])
      }
    }
    for (var i = 0; i < arrayOfText.length; i++) {
      if (i === arrayOfText.length - 1) { //last item
        text = text + arrayOfText[i];
      } else {
        text = text + arrayOfText[i] + ', ';
      }
    }
    return text;

  };

  const openDeleteModal = (product) => {
    setSelectedProduct(product);
    setDeleteModal(true);
  };

  const openEditModal = (product) => {
    setSelectedProduct(product);
    setEditModal(true);
  };

  useEffect(() => {
    if (reload) {
      loadData().then(() => {
        setReload(false);
        setShowMessage(true);
        setTimeout(() => {
          setShowMessage(false);
        }, 2000);
      });
    }
  }, [reload]);// eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    loadData();
  }, [context.selectedFacility]);// eslint-disable-line react-hooks/exhaustive-deps

  return isLoading ? <GenericLoader /> : (
    <div style={{ padding: "16px" }}>
      <div className='dashboard' style={{ marginBottom: "16px" }}>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <div>
            <span>Cafe Stock Master</span>
          </div>
          <div style={{ alignItems: "center" }}>
            <span>
              <Button
                onClick={() => { setAddProduct(true) }}
                name={"Add Category"}
              >
                <Icon name={"add"} />
                Add a Product In Cafe Master
              </Button>
            </span>
          </div>
        </div>
      </div>
      {loader ? (
        <GenericLoader />
      ) : (
        <div style={{ paddingTop: "16px" }}>
          <Toast
            showMessage={showMessage}
            setShowMessage={setShowMessage}
            message={messageContent.message}
            messageHeader={messageContent.header}
            color={messageContent.color}
          />
          <Table celled padded>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>S.No</Table.HeaderCell>
                <Table.HeaderCell>Product Name</Table.HeaderCell>
                <Table.HeaderCell>Company</Table.HeaderCell>
                <Table.HeaderCell>Category</Table.HeaderCell>
                <Table.HeaderCell>Sub Category</Table.HeaderCell>
                <Table.HeaderCell>Available For</Table.HeaderCell>
                <Table.HeaderCell>Reorder Level</Table.HeaderCell>
                <Table.HeaderCell>Product Id</Table.HeaderCell>
                <Table.HeaderCell>Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {stockMaster.map((x, index) => {
                // const x = y.data;
                return (
                  <Table.Row key={x.id} >
                    <Table.Cell>{index + 1}</Table.Cell>
                    <Table.Cell>{x.productName}</Table.Cell>
                    <Table.Cell>{x.companyName}</Table.Cell>
                    <Table.Cell>{x.category}</Table.Cell>
                    <Table.Cell>{x.subCategory ? x.subCategory : ''}</Table.Cell>
                    <Table.Cell>{availableForName(x)} </Table.Cell>
                    <Table.Cell>{x.reorderLevel} </Table.Cell>
                    <Table.Cell>{x.id} </Table.Cell>

                    <Table.Cell
                      style={{
                        flex: 1,
                        justifyContent: "center",
                        flexDirection: "column",
                        display: "flex",
                      }}
                    >
                      <Button
                        style={{
                          padding: "8px",
                          fontSize: "12px",
                          marginBottom: "8px",
                          backgroundColor: colors.orange["800"],
                          color: "#fff",
                        }}
                        onClick={() => openEditModal(x)}
                      >
                        Edit
                      </Button>
                      <Button
                        style={{
                          padding: "8px",
                          fontSize: "12px",
                          marginBottom: "8px",
                        }}
                        onClick={() => openDeleteModal(x)}
                      >
                        Delete
                      </Button>
                    </Table.Cell>
                  </Table.Row>

                );
              })}
            </Table.Body>
          </Table>
          <br />

How to Manage Stock Master?
<Table celled padded>
  <Table.Header>
    <Table.Row>
      <Table.HeaderCell>Cafe Stock Module Overview</Table.HeaderCell>
      <Table.HeaderCell> Add Product to Cafe Master</Table.HeaderCell>
    </Table.Row>
  </Table.Header>
  <Table.Body>
    <Table.Row key={0}>
      <Table.Cell><div className="player2">
        <iframe width="200" height="100" src="https://www.youtube.com/embed/aHEhpG25AJ8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allow="fullscreen;"></iframe>
      </div></Table.Cell>
      <Table.Cell><div className="player2">
        <iframe width="200" height="100" src="https://www.youtube.com/embed/Xd4NDXEAb8A" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allow="fullscreen;"></iframe>
      </div></Table.Cell>
    </Table.Row>
  </Table.Body>

</Table>
        </div>

      )}
      {addProduct ? (
        <AddProductToCafeMaster
          history={history}
          showModal={setAddProduct}
          setReload={setReload}
          setMessage={setMessageContent}
          isLoading={setisLoading}
        />
      ) : null}
      {deleteModal ? (
        <DeleteProductFromCafeMaster
          history={history}
          showModal={setDeleteModal}
          setReload={setReload}
          productData={selectedProduct}
          setMessage={setMessageContent}
          isLoading={setisLoading}
        />
      ) : null}


      {editModal ? (
        <EditProductInCafeMaster
          history={history}
          showModal={setEditModal}
          setReload={setReload}
          productData={selectedProduct}
          setMessage={setMessageContent}
          isLoading={setisLoading}
        />
      ) : null}
    </div>
  );
};

export default CafeStockMaster;
