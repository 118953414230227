import React, { useContext, useState, useEffect, useRef } from 'react';
import { colors } from "@material-ui/core";
import {
    Button,
    Dropdown,
    Form,
} from "semantic-ui-react";

import ModalPortal from '../../generic/ModalPortal';
import { AppContext } from '../../../AppContext';
import { getAllCategories, addProductsToRegister,getProductsFromCategorySubCategory } from '../../../apiclients/ProductApiClient';
import { CATEGORY_TYPE } from '../../../utils/constants';
const AddProductToRegister = (props) => {
    const { showModal, setReload, setMessage, isLoading } = props;
    const context = useContext(AppContext);
    const facilityId = context.selectedFacility;
    const arenaId = context.selectedArena.arenaId;
    const rawCategories = useRef([]);
    const [category, setCategory] = useState();
    const [subCategory, setSubCategory] = useState();
    const [productQuantity, setProductQuantity] = useState();
    const [sellingPrice, setSellingPrice] = useState();
    const [rentalPrice, setRentalPrice] = useState();
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [isSubCategoryAvailable, setIsSubCategoryAvailable] = useState(false);
    const [isProductAvailable, setIsProductAvailable] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [listOfAvailableProduct, setListOfAvailableProduct] = useState({});
    const [selectedProduct, setSelectedProduct] = useState();
    const [isRentalApplicable,setIsRentalApplicable] = useState(false);

    const isSubmitAllowed = selectedProduct && productQuantity > 0 && sellingPrice > -1 ;

    useEffect(() => {
        getAllCategories(CATEGORY_TYPE.product).then((response) => {
            const availableCategories = response.data.data.categories;
            rawCategories.current = availableCategories;
            const cate = availableCategories.map((x) => {
                return { key: x.id, value: x.name, text: x.name }
            })
            setCategories(cate);
        });

    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const getProductData = (cat, subCat) => {
        getProductsFromCategorySubCategory(facilityId, cat, subCat, CATEGORY_TYPE.product).then((response) => {
            const products = response.data;
            if (products.length === 0) {
                setIsProductAvailable(false);
                setShowErrorMessage(true);
            } else {
                setShowErrorMessage(false);
                const pro = products.map((x) => {
                    return { key: x.id, value: x.id, text: x.productName, ...x }
                });
                setListOfAvailableProduct(pro);
                setIsProductAvailable(true);
            }
        })
    }

    const changeCategory = (event, { value }) => {
        const data = rawCategories.current.find((category) => category.name === value);
        setSelectedProduct();
        setSubCategory();
        if (data.subCategories) {
            setIsSubCategoryAvailable(true);
            setIsProductAvailable(false);
            const subCate = data.subCategories.map((x) => {
                return { key: x.id, value: x.name, text: x.name }
            });
            setSubCategories(subCate);
        } else {
            getProductData(value);
            setIsSubCategoryAvailable(false);
            setSubCategories([]);
        }
        setCategory(value);
    };
    const changeSubCategory = (event, { value }) => {
        setSelectedProduct();
        setSubCategory(value);
        getProductData(category, value);
    };
    const changeProduct = (event, { value }) => {
        let productDetails = listOfAvailableProduct.find((x)=> x.id === value);
        setIsRentalApplicable(productDetails.availableForRent);
        setSelectedProduct(value);
    };

    const modalContent = (
        <div>
            <Form>
                <Form.Group widths='equal'>
                    <Form.Field  >

                        <label>Select Category</label>
                        <Dropdown
                            onChange={changeCategory}
                            placeholder='Select...'
                            openOnFocus
                            selection
                            value={category}
                            options={categories}
                            style={{ marginRight: "30px" }}
                        />
                    </Form.Field>

                    {isSubCategoryAvailable ? <Form.Field  >

                        <label>Select Sub-Category</label>
                        <Dropdown
                            onChange={changeSubCategory}
                            placeholder='Select...'
                            openOnFocus
                            selection
                            value={subCategory}
                            options={subCategories}
                            style={{ marginRight: "30px" }}
                        />
                    </Form.Field> : null}

                    {isProductAvailable ? <Form.Field  >

                        <label>Select Product</label>
                        <Dropdown
                            onChange={changeProduct}
                            placeholder='Select...'
                            openOnFocus
                            selection
                            value={selectedProduct}
                            options={listOfAvailableProduct}
                            style={{ marginRight: "30px" }}
                        />
                    </Form.Field> : null}
                </Form.Group>
                {showErrorMessage ? <div>
                        <p>Stock is not avaiable for selected category.</p>
                        </div> : 
                        <Form.Group widths='equal'>
                        <Form.Field  >
                            <label>Quantity</label>
                            <input
                                style={{ marginRight: "6px" }}
                                type='number'
                                value={productQuantity}
                                placeholder='Product Quantity'
                                onChange={(evt) => setProductQuantity(evt.target.value)}
                            />
                        </Form.Field>
                        <Form.Field  >
                            <label>Selling Price</label>
                            <input
                                style={{ marginRight: "6px" }}
                                type='number'
                                value={sellingPrice}
                                placeholder='In Rupees, inclusive of taxes'
                                onChange={(evt) => setSellingPrice(evt.target.value)}
                            />
                        </Form.Field>

                        { isRentalApplicable ? <Form.Field  >
                            <label>Rental Price</label>
                            <input
                                style={{ marginRight: "6px" }}
                                type='number'
                                value={rentalPrice}
                                placeholder='In Rupees, inclusive of taxes'
                                onChange={(evt) => setRentalPrice(evt.target.value)}
                            />
                        </Form.Field> : null}
                    </Form.Group>     
                        }
            </Form>
        </div>
    );

    const renderAction = () => {
        return (
            <React.Fragment>
                <Button onClick={() => showModal(false)} className='ui button'>Cancel</Button>

                <Button disabled={!isSubmitAllowed} primary onClick={addProductToRegister}>  Submit</Button>
            </React.Fragment>
        );
    };

    const addProductToRegister = () => {

        showModal(false);
        isLoading(true);
        let postBody = listOfAvailableProduct.find((x)=> x.id === selectedProduct);
        postBody.productQuantity =Number(productQuantity);
        postBody.sellingPrice =Number(sellingPrice);
        postBody.rentalPrice =Number(rentalPrice);
        postBody.createdBy =  {
            userName: context.user.userName,
            userId: context.user.userId,
        };
        addProductsToRegister(
            facilityId,
            arenaId,
            postBody
        ).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setMessage({
                    header: "Product Added to the register",
                    message: "Product has been added successfully.",
                    color: "green",
                });
            } else {
                setMessage({
                    header: "Error!",
                    message: response.data.error.message,
                    color: "red",
                });
            }
            setReload(true);
        }).catch((error) => {
            setMessage({
                header: "Error!",
                message: error.message,
                color: "red",
            });
        });
    }
    return (
        <>
            <ModalPortal
                title='Add Product in Register'
                content={modalContent}
                action={renderAction()}
                onDismiss={() => showModal(false)}
            />
        </>

    );


}

export default AddProductToRegister;