import React, { useEffect, useContext, useState, useReducer } from "react";
import { Button, Icon, Modal, Table } from "semantic-ui-react";

import "../dashboard/style.scss";
import { AppContext } from "../../AppContext";
import { loadBookingTransactions } from "../../apiclients/ReportsApiClient";
import {
  convertFirstoreDate,
  convertFirstoreDateForExcel,
  getKeyValue,
  getSubtypeLabel,
  getBookingStatusLabel,
} from "../../utils/helperFunctions";
import CustomModal from "../generic/CustomModal";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { colors } from "@material-ui/core";
import {
  filterReducer,
  FILTERS_ACTION_TYPE,
  initialFilterState,
} from "./bookingReportReducer";
import GenericLoader from "../generic/GenericLoader";
import TransactionDetails from "./TransactionDetails";
import ExportToExcel from "../generic/ExportToExcel";
import { BOOKING_SOURCES, PAYMENT_STATUS, ROLES } from "../../utils/constants";
import moment from "moment";
import Toast from "../generic/Toast";

const paymentOptions = [
  { label: "Paytm", value: "paytm" },
  { label: "Credit/Debit Card", value: "card" },
  { label: "Cash", value: "cash" },
  { label: "Bank Transfer", value: "bankTransfer" },
  { label: "Other", value: "other" },
  { label: "Online", value: "Online" },
];

const bookingOptions = [
  { label: "Play.all", value: BOOKING_SOURCES.PLAY_ALL_WEB },
  { label: "Hudle", value: "Hudle" },
  { label: "Sportido", value: "Sportido" },
  { label: "Playo", value: "Playo" },
  { label: "Others", value: "others" },
];



const FilterFooter = ({ setShowFilter, onApply }) => {
  return (
    <Modal.Actions>
      <Button className={"ui button booked-footer"} onClick={onApply}>
        Apply
      </Button>
      <Button onClick={() => setShowFilter(false)}>Cancel</Button>
    </Modal.Actions>
  );
};

const FilterContent = ({ onApply, setShowFilter, selectedFilters, role }) => {
  const [filters, dispatch] = useReducer(filterReducer, {
    ...initialFilterState,
    ...selectedFilters,
  });
  const { startDate, endDate, paymentMode, bookingModes } = filters;

  const onReset = () => {
    dispatch({ type: FILTERS_ACTION_TYPE.RESET });
  };
  const onFilterApplyClicked = () => {
    onApply(filters);
  };
  const updateSelectedModes = (mode) => () => {
    dispatch({
      type: FILTERS_ACTION_TYPE.ADD_PAYMENT_MODES,
      payload: paymentMode.value === mode.value ? {} : mode,
    });
  };

  const updateSelectedBookingModes = (mode) => () => {
    const isAdded = bookingModes.some((x) => x.value === mode.value);
    if (isAdded) {
      dispatch({
        type: FILTERS_ACTION_TYPE.ADD_BOOKING_MODES,
        payload: bookingModes.filter((x) => x.value !== mode.value),
      });
    } else {
      dispatch({
        type: FILTERS_ACTION_TYPE.ADD_BOOKING_MODES,
        payload: [...bookingModes, mode],
      });
    }
  };

  return (
    <>
      <Modal.Header>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>Filters</span>
          <span
            style={{
              textTransform: "uppercase",
              fontSize: "14px",
              color: colors.orange[500],
            }}
            onClick={onReset}
          >
            Clear all
          </span>
        </div>
      </Modal.Header>
      <Modal.Content>
        <div>
          <div
            style={{
              paddingTop: "20px",
              paddingBottom: "10px",
              fontWeight: "bold",
            }}
          >
            Transaction Date
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <div style={{ fontWeight: "bold" }}>Start Date</div>
            <KeyboardDatePicker
              autoOk
              variant='inline'
              value={startDate}
              placeholder='Select Date...'
              onChange={(date) =>
                dispatch({
                  type: FILTERS_ACTION_TYPE.ADD_START_DATE,
                  payload: date,
                })
              }
              format='DD MMM yyyy'
              style={{ padding: "10px" }}

              // minDate={!(role === ROLES.ADMIN || role === ROLES.PARTNER || role === ROLES.ACCOUNTANT || role === ROLES.AUDITOR ) ? (role === ROLES.REGIONAL_MANAGER  || role === ROLES.QC_MANAGER) ? moment().subtract(14, 'days'): moment().subtract(7, 'days') : undefined}
              maxDate={moment()}
            />
            <div style={{ fontWeight: "bold" }}>End Date</div>
            <KeyboardDatePicker
              autoOk
              variant='inline'
              value={endDate}
              placeholder='Select Date...'
              onChange={(date) =>
                dispatch({
                  type: FILTERS_ACTION_TYPE.ADD_END_DATE,
                  payload: date,
                })
              }
              format='DD MMM yyyy'
              style={{ padding: "10px" }}


              minDate={!(role === ROLES.ADMIN || role === ROLES.PARTNER || role === ROLES.ACCOUNTANT ||  role === ROLES.AUDITOR) ? moment().subtract(7, 'days') : undefined}
              maxDate={moment()}
            />
          </div>
          <div
            style={{
              paddingTop: "20px",
              paddingBottom: "10px",
              fontWeight: "bold",
            }}
          >
            Payment Mode
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            {paymentOptions.map((x) => {
              const isActive = paymentMode && paymentMode.value === x.value;
              return (
                <Button
                  onClick={updateSelectedModes(x)}
                  style={{
                    marginRight: "10px",
                    backgroundColor: isActive
                      ? colors.orange[700]
                      : colors.grey[50],
                    color: isActive ? colors.blue[50] : colors.black,
                  }}
                >
                  {x.label}
                </Button>
              );
            })}
          </div>
          <div
            style={{
              paddingTop: "20px",
              paddingBottom: "10px",
              fontWeight: "bold",
            }}
          >
            Booking Mode
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            {bookingOptions.map((x) => {
              const isActive = bookingModes.some(
                (mode) => mode.value === x.value
              );
              return (
                <Button
                  onClick={updateSelectedBookingModes(x)}
                  style={{
                    marginRight: "10px",
                    backgroundColor: isActive
                      ? colors.orange[700]
                      : colors.grey[50],
                    color: isActive ? colors.blue[50] : colors.black,
                  }}
                >
                  {x.label}
                </Button>
              );
            })}
          </div>
        </div>
      </Modal.Content>
      <FilterFooter
        onApply={onFilterApplyClicked}
        setShowFilter={setShowFilter}
      />
    </>
  );
};

const BookingFilters = ({
  setShowFilter,
  showFilter,
  selectedFilters,
  onApply,
  role
}) => {
  return (
    <CustomModal
      comp={true}
      content={
        <FilterContent
          onApply={onApply}
          selectedFilters={selectedFilters}
          setShowFilter={setShowFilter}
          role={role}
        />
      }
      setOpen={setShowFilter}
      open={showFilter}
    />
  );
};
const AcademyTransactionReport = () => {
  const { selectedFacility, selectedArena, user } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState(initialFilterState);
  const [showFilter, setShowFilter] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [loadData, setLoadData] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const [messageContent, setMessageContent] = useState({});
  const [grossReceipts, setGrossReceipt] = useState(0);
  const [commissions, setCommissions] = useState(0);
  const [taxes, setTaxes] = useState(0);
  const [netReceipts, setNetReceipt] = useState(0);
  const arenaIndex = user.facilityIncharge.findIndex(x=>x.arenaId === selectedArena.arenaId);
  
  useEffect(() => {
    if (!loadData) {
      setLoadData(true);
    }
  }, [selectedArena]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (loadData) {
      const params = {
        startDate: selectedFilters.startDate,
        endDate: selectedFilters.endDate,
        type: "academy",
      };
      if (getKeyValue(selectedFilters, "paymentMode.value")) {
        params.paymentMode = selectedFilters.paymentMode.value;
      }
      if (
        selectedFilters.bookingModes &&
        selectedFilters.bookingModes.length > 0
      ) {
        params.bookingModes = selectedFilters.bookingModes.map((x) => x.value);
      }

      loadBookingTransactions(
        selectedFacility,
        selectedArena.arenaId,
        params
      ).then((res) => {
        let transactionData = res.data.data;
        if(user.facilityIncharge[arenaIndex].sportIds){ // that means the user access is restricted to a sport
          const listOfSports = user.facilityIncharge[arenaIndex].sportIds;
          transactionData  = transactionData.filter(x=>x.sport && listOfSports.includes(x.sport.sportId))
        }
        setData(transactionData);
        let summary = { grossReceipts: 0, commissions: 0, taxes: 0 };
       
        for (var i = 0; i < transactionData.length; i++) {
          const transData = transactionData[i];
          summary.grossReceipts = summary.grossReceipts + transData.amountPaid;
          if(transData.hudlePassDiscount){
            let localCommissions = (transData.totalAmount * transData.hudlePassDiscount) ? (transData.totalAmount * transData.hudlePassDiscount) : 0
            summary.commissions = summary.commissions + localCommissions;
          }
          if(transData.thirdPartyCommission){
            let localCommission = (transData.totalAmount * transData.thirdPartyCommission) ? (transData.totalAmount * transData.thirdPartyCommission) : 0
            summary.commissions = summary.commissions + localCommission;
          }
          if(transData.cgst){
            summary.taxes = summary.taxes + transData.cgst;
          }
          if(transData.sgst){
            summary.taxes = summary.taxes + transData.sgst;
          }
          if(transData.igst){
            summary.taxes = summary.taxes + transData.igst;
          }
          if(transData.cess){
            summary.taxes = summary.taxes + transData.cess;
          }

        }
        summary.grossReceipts = Math.round(summary.grossReceipts*100)/100;
        let netReceipt = summary.grossReceipts - summary.commissions - summary.taxes;
        netReceipt = Math.round(netReceipt*100)/100;

        summary.commissions = Math.round(summary.commissions*100)/100;
        summary.taxes = Math.round(summary.taxes*100)/100;
       setGrossReceipt(summary.grossReceipts);
        setCommissions(summary.commissions);
        setTaxes(summary.taxes);
        setNetReceipt(netReceipt);
        setLoadData(false);
        setTimeout(() => {
          setShowMessage(false);
        }, 2000);
      });
    }
  }, [loadData]); // eslint-disable-line react-hooks/exhaustive-deps

  const createCourtName =(courts)=>{
    const data = courts.map(x=>x.courtName).join(', ');
    return data;
  }
  const excelFirstHalf = [
    { label: "Booking Type", value: "type" },
    { label: "Sub Category", value: (col) => getSubtypeLabel(col.subtype) },
    { label: "Arena", value: "arenaName" },
    { label: "Sport", value: (col) => (col.alternativeSport ? col.alternativeSport.text :col.sport ? col.sport.sportName : "") },
    {
      label: "Transaction Date",
      value: (col) => convertFirstoreDateForExcel(col.createdDate),
    },
    {
      label: "Transaction Time",
      value: (col) => col.createdTime,
    }, {
      label: "Booking Date",
      value: (col) => (col.bookingDate ? convertFirstoreDateForExcel(col.bookingDate) :
       col.startDate && col.bulkBookingID ? col.startDate+' to '+col.endDate :
       ""),
    },
    {
      label: "Slot Name",
      value: (col) => (col.slot ? col.slot.interval ? col.slot.interval.start + '-' + col.slot.interval.end : "" :
      col.slotDuration ? col.slotDuration :
      ""),
    },
    {
      label: "Court Name",
      value: (col) => (col.court ? col.court.courtName : col.courts ? col.courts.map(x=>x.courtName).join(', '):""),
    },
  ];
  const excelSecondHalf = [{ label: "Customer Name", value: "userName" },
    { label: "Batch Name", value: "batchName" },
  { label: "Transaction ID", value: "id" },
  { label: "Total Amount (in Rs.)", value: "totalAmount" },
  { label: "Discount (in %)", value: "discount" },
  { label: "Amount Paid (in Rs.)", value: "amountPaid" },
  { label: "Amount Due (in Rs.)", value: "amountDue" },
  {
    label: "Paytm (in Rs.)",
    value: (col) =>
      col.payment
        ? col.payment
          .filter((x) => x.mode.toLowerCase() === "paytm")
          .map((x) => x.amount)
          .reduce((x, sum) => Number(sum) + Number(x), 0)
        : "",
  },
  {
    label: "Card Payment (in Rs.)",
    value: (col) =>
      col.payment
        ? col.payment
          .filter((x) => x.mode.toLowerCase() === "card")
          .map((x) => x.amount)
          .reduce((x, sum) => Number(sum) + Number(x), 0)
        : "",
  },
  {
    label: "Cash (in Rs.)",
    value: (col) =>
      col.payment
        ? col.payment
          .filter((x) => x.mode.toLowerCase() === "cash")
          .map((x) => x.amount)
          .reduce((x, sum) => Number(sum) + Number(x), 0)
        : "",
  },
  {
    label: "Bank Transfer (in Rs.)",
    value: (col) =>
      col.payment
        ? col.payment
          .filter((x) => x.mode.toLowerCase() === "banktransfer")
          .map((x) => x.amount)
          .reduce((x, sum) => Number(sum) + Number(x), 0)
        : "",
  },
  {
    label: "Others (in Rs.)",
    value: (col) =>
      col.payment
        ? col.payment
          .filter((x) => x.mode.toLowerCase() === "others")
          .map((x) => x.amount)
          .reduce((x, sum) => Number(sum) + Number(x), 0)
        : "",
  },

  {
    label: "Online (in Rs.)",
    value: (col) =>
      col.payment
        ? col.payment
          .filter((x) => x.mode.toLowerCase() === "online")
          .map((x) => x.amount)
          .reduce((x, sum) => Number(sum) + Number(x), 0)
        : "",
  },
  {
    label: "Third Party Commission",
    value: (col) =>
      col.thirdPartyCommission
        ? col.thirdPartyCommission * col.totalAmount
        : "",
  },
  {
    label: "Hudle Pass Discount",
    value: (col) =>
      col.hudlePassDiscount
        ? col.hudlePassDiscount * col.totalAmount
        : "",
  },
  { label: "CGST", value: (col) => col.cgst ? col.cgst : "", },
  { label: "IGST", value: (col) => col.igst ? col.igst : "", },
  { label: "SGST", value: (col) => col.sgst ? col.sgst : "", },
  { label: "Cess", value: (col) => col.cess ? col.cess : "", },
  { label: "Taxable Amount", value: (col) => col.taxableAmount ? col.taxableAmount : "", },
  { label: "Net Receipt", value: (col) => col.taxableAmount && col.thirdPartyCommission ? col.taxableAmount - (col.thirdPartyCommission * col.totalAmount) : col.taxableAmount ? col.taxableAmount : "", },

  {
    label: "Booking Date",
    value: (col) => convertFirstoreDate(col.bookingDate),
  },
  { label: "Booking Source", value: "source" },
  { label: "Booking Sub-Source", value: "bookingSource" },
  { label: "Created By", value: (col) => col.createdBy ? col.createdBy.userName : "" },
  {
    label: "Partner Booking ID",
    value: (col) => (col.partnerBookingId ? col.partnerBookingId : ""),
  },
  { label: "Platform", value: (col) => col.platform ? col.platform : "" },
  { label: "Payment Status", value: (col) => col.paymentStatus ? col.paymentStatus : "" },
  { label: "Booking Status", value: "bookingStatus"},
  { label: "Is Canceled", value: (col) => col.cancelled ? "Yes" : "No" },
  { label: "Receipt/Credit Note No.", value: (col) => col.receiptNumber ? col.receiptNumber : col.creditNoteNumber ? col.creditNoteNumber : "" },
]
  let tableHeader;
  if (user.role === ROLES.PARTNER || user.role === ROLES.QC_MANAGER) { // report in case of partner
    tableHeader = [

      { label: "Booking Type", value: "type" },
      { label: "Sub Category", value: (col) => getSubtypeLabel(col.subtype) },
      {
        label: "Transaction Date",
        value: (col) => convertFirstoreDateForExcel(col.createdDate),
      },
      {
        label: "Transaction Time",
        value: (col) => col.createdTime,
      },

      { 
        label: "Sport", 
      value: (col) => (col.alternativeSport ? col.alternativeSport.text : col.sport ? col.sport.sportName : "") 
    },
      {
        label: "Booking Date",
        value: (col) => (col.bookingDate ? convertFirstoreDateForExcel(col.bookingDate) : ""),
      },
      {
        label: "Slot Name",
        value: (col) => (col.slot ? col.slot.interval ? col.slot.interval.start + '-' + col.slot.interval.end : "" : ""),
      },
      {
        label: "Court Name",
        value: (col) => (col.court ? col.court.courtName : ""),
      },
      { label: "Customer Name", value: "userName" },

      { label: "Amount Paid (in Rs.)", value: "amountPaid" },

      {
        label: "Third Party Commission",
        value: (col) =>
          col.thirdPartyCommission && col.hudlePassDiscount
            ? (col.hudlePassDiscount * col.totalAmount) + (col.thirdPartyCommission * col.totalAmount)
            : col.thirdPartyCommission ? col.thirdPartyCommission * col.totalAmount
              : col.hudlePassDiscount ? col.hudlePassDiscount * col.totalAmount
                : "",
      },
      {
        label: 'Total Taxes',
        value: (col) => col.cgst ? col.cgst : 0 + col.igst ? col.igst : 0 + col.sgst ? col.sgst : 0 + col.cess ? col.cess : 0,
      },
      { label: "Net Receipt", value: (col) => col.taxableAmount && col.thirdPartyCommission ? col.taxableAmount - (col.thirdPartyCommission * col.totalAmount) : col.taxableAmount ? col.taxableAmount : "", },

      { label: "Booking Source", value: "source" },
      { label: "Booking Sub-Source", value: "bookingSource" },

    { label: "Platform", value: (col) => col.platform ? col.platform : "" },

    { label: "Payment Status", value: (col) => col.paymentStatus ? col.paymentStatus : "" },
    ];
  } else 
  {
    tableHeader = [...excelFirstHalf, {
      label: "Customer Number",
      value: (col) => (col.userMobileNumber &&  user.role !== ROLES.AUDITOR? col.userMobileNumber : ""),
    }, ...excelSecondHalf]
  }

  const onApply = (filters) => {
    setLoadData(true);
    setSelectedFilters(filters);
    setShowFilter(false);
  };

  const openFilter = () => {
    setShowFilter(true);
  };

  const openTransactionDetail = (data) => () => {
    setShowDetails(data);
  };

  const startDate = convertFirstoreDate(
    selectedFilters.startDate,
    "DD MMM YYYY"
  );
  const endDate = convertFirstoreDate(selectedFilters.endDate, "DD MMM YYYY");
  const fileName = 'Transactions ' + startDate + " - " + endDate;
  const sheetName = moment().format('MMMM Do YYYY, h_mm a');

  const sheets = [
    {
      name: sheetName,
      columns: tableHeader,
      dataSet: data,
    },
  ];


  return (
    <div style={{ padding: "16px" }}>
      <Toast
        showMessage={showMessage}
        setShowMessage={setShowMessage}
        message={messageContent.message}
        messageHeader={messageContent.header}
        color={messageContent.color}
      />
      <div style={{ paddingBottom: "16px" }}>
        <div className='dashboard'>
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
            }}
          >
            <div>
              <span > Academy Transaction Report </span>
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  paddingRight: "10px",
                }}
              >
                ( Range: {startDate}- {endDate})
              </span>
            </div>
            <div style={{ alignItems: "center" }}>
              <span><span>
                <ExportToExcel disabled={data.length === 0 || !(user.role === ROLES.ADMIN  || user.role === ROLES.ACCOUNTANT  || user.role === ROLES.COACH)} sheets={sheets}
                  fileName={fileName}
                  buttonName="Download Report" />
              </span>
                <Button onClick={openFilter} name={"filter"}>
                  <Icon
                    color={selectedFilters.isFilterApplied ? "orange" : "grey"}
                    name={"filter"}
                  />
                  Filter
                </Button>
              </span>
            </div>
          </div>
        </div>
      </div>

      {showFilter ? (
        <BookingFilters
          onApply={onApply}
          selectedFilters={selectedFilters}
          setLoadData={setLoadData}
          setShowFilter={setShowFilter}
          showFilter
          role={user.role}
        />
      ) : null}
      {!!showDetails ? (
        <TransactionDetails
          selectedData={showDetails}
          setShowDetails={setShowDetails}
          role={user.role}
          setMessage={setShowMessage}
          setMessageContent={setMessageContent}
          isLoading={setLoadData}
        />
      ) : null}
      {loadData ? (
        <GenericLoader />
      ) : data.length === 0 ? (
        "No data available"
      ) : (
        <div>
          <Table celled padded>
            <Table.Header>
              <Table.Row>

                <Table.HeaderCell>Gross Receipts</Table.HeaderCell>
                <Table.HeaderCell>Third Party Commissions</Table.HeaderCell>
                <Table.HeaderCell>Taxes</Table.HeaderCell>
                <Table.HeaderCell>Net Receipts</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
          <Table.Row>
              <Table.Cell>
                Rs. {grossReceipts} /-
              </Table.Cell>
              <Table.Cell>
                Rs. {commissions} /-
              </Table.Cell>
              <Table.Cell>
                Rs. {taxes} /-
              </Table.Cell>
              <Table.Cell>
                Rs. {netReceipts} /-
              </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          <Table celled padded>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Date of Transaction</Table.HeaderCell>
                <Table.HeaderCell>Customer Detail</Table.HeaderCell>
                <Table.HeaderCell>Transaction Amount</Table.HeaderCell>
                <Table.HeaderCell>Sport</Table.HeaderCell>
                <Table.HeaderCell>Arena</Table.HeaderCell>
                <Table.HeaderCell>Source</Table.HeaderCell>
                <Table.HeaderCell>Batch Name</Table.HeaderCell>
                <Table.HeaderCell>Type</Table.HeaderCell>
                <Table.HeaderCell>Created By</Table.HeaderCell>
                <Table.HeaderCell>Transaction ID</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {data.map((x) => {
                console.log(x.id);
                console.log(x);
                console.log("-----------");
                return (
                  <Table.Row
                    style={
                      x.amountDue ? { backgroundColor: colors.red["50"] } : {}
                    }
                  >
                    <Table.Cell>
                      <div>
                        {convertFirstoreDate(x.createdDate)}, {x.createdTime}
                      </div>
                    </Table.Cell>
                    <Table.Cell>
                      <div>{x.userName}</div>
                      <div>{!(user.role === ROLES.PARTNER || user.role === ROLES.AUDITOR || user.role === ROLES.QC_MANAGER) ? x.userMobileNumber : null}</div>
                    </Table.Cell>
                    <Table.Cell>
                      Rs.{x.amountPaid}/-{" "}
                      <div style={{ color: colors.red["300"] }}>
                        {x.amountDue ? "Due Amount: " + x.amountDue : ""}
                      </div>
                    </Table.Cell>
                    
                    <Table.Cell>{x.alternativeSport ? 
                    x.alternativeSport.text 
                    : x.sport? 
                    x.sport.sportName 
                    : x.sportName?  
                    x.sportName.text ? x.sportName.text 
                    : Array.isArray(x.sportName) ? 
                    x.sportName.map(x=>x.sportName+', ')
                    :x.sportName
                    :''}</Table.Cell>
                    <Table.Cell>{x.arenaName || ""}</Table.Cell>
                    <Table.Cell>{getSubtypeLabel(x.source)}</Table.Cell>
                    <Table.Cell>{x.batchName}</Table.Cell>
                    <Table.Cell style={{ textTransform: "capitalize" }}>
                      {x.type}, {getSubtypeLabel(x.subtype)}
                      {getBookingStatusLabel(x.bookingStatus)}
                    </Table.Cell>
                    <Table.Cell>{x.createdBy?.userName || "-"}</Table.Cell>
                    <Table.Cell onClick={openTransactionDetail(x)}>
                      <div
                        style={{
                          textDecoration: "underline",
                          color: colors.blue[500],
                        }}
                      >
                        {x.id}
                      </div>
                      {x.rescheduleTo ? (
                        <div style={{ color: "red" }}>Old Slot</div>
                      ) : x.rescheduledFrom ? (
                        <div style={{ color: "red" }}>New Slot</div>
                      ) : x.cancelled ? (
                        <div style={{ color: "grey" }}>Cancelled</div>
                      ) : x.paymentStatus === PAYMENT_STATUS.PENDING && <div style={{ color: "red" }}>Payment Pending</div>}
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </div>
      )}
    </div>
  );
};

export default AcademyTransactionReport;
